import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _utils2Dew } from "../../libs/utils";
import { dew as _Node3Dew } from "./Node";
import { dew as _locale3Dew } from "../locale";
import { dew as _treeStore3Dew } from "./model/tree-store";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _utils = _utils2Dew();

  var _Node = _Node3Dew();

  var _Node2 = _interopRequireDefault(_Node);

  var _locale = _locale3Dew();

  var _locale2 = _interopRequireDefault(_locale);

  var _treeStore = _treeStore3Dew();

  var _treeStore2 = _interopRequireDefault(_treeStore);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Tree = function (_Component) {
    (0, _inherits3.default)(Tree, _Component);

    function Tree(props) {
      (0, _classCallCheck3.default)(this, Tree);

      var _this = (0, _possibleConstructorReturn3.default)(this, (Tree.__proto__ || Object.getPrototypeOf(Tree)).call(this, props));

      var _this$props = _this.props,
          data = _this$props.data,
          lazy = _this$props.lazy,
          options = _this$props.options,
          load = _this$props.load,
          defaultCheckedKeys = _this$props.defaultCheckedKeys,
          defaultExpandedKeys = _this$props.defaultExpandedKeys,
          currentNodeKey = _this$props.currentNodeKey,
          nodeKey = _this$props.nodeKey,
          checkStrictly = _this$props.checkStrictly,
          autoExpandParent = _this$props.autoExpandParent,
          defaultExpandAll = _this$props.defaultExpandAll,
          filterNodeMethod = _this$props.filterNodeMethod;
      _this.state = {
        store: new _treeStore2.default({
          key: nodeKey,
          data: data,
          lazy: lazy,
          props: options,
          load: load,
          currentNodeKey: currentNodeKey,
          checkStrictly: checkStrictly,
          defaultCheckedKeys: defaultCheckedKeys,
          defaultExpandedKeys: defaultExpandedKeys,
          autoExpandParent: autoExpandParent,
          defaultExpandAll: defaultExpandAll,
          filterNodeMethod: filterNodeMethod
        }),
        currentNode: null
      };
      return _this;
    }

    (0, _createClass3.default)(Tree, [{
      key: "componentWillReceiveProps",
      value: function componentWillReceiveProps(nextProps) {
        if (nextProps.data instanceof Array && this.props.data !== nextProps.data) {
          this.root.setData(nextProps.data);
          this.setState({}); //force update
        }
      }
    }, {
      key: "filter",
      value: function filter(value) {
        if (!this.props.filterNodeMethod) throw new Error("[Tree] filterNodeMethod is required when filter");
        this.store.filter(value);
        this.refresh();
      }
    }, {
      key: "refresh",
      value: function refresh() {
        this.setState({});
      }
    }, {
      key: "getNodeKey",
      value: function getNodeKey(node, otherwise) {
        var nodeKey = this.props.nodeKey;

        if (nodeKey && node) {
          return node.data[nodeKey];
        }

        return otherwise;
      }
    }, {
      key: "getCheckedNodes",
      value: function getCheckedNodes(leafOnly) {
        return this.store.getCheckedNodes(leafOnly);
      }
    }, {
      key: "getCheckedKeys",
      value: function getCheckedKeys(leafOnly) {
        return this.store.getCheckedKeys(leafOnly);
      }
    }, {
      key: "setCheckedNodes",
      value: function setCheckedNodes(nodes, leafOnly) {
        if (!this.props.nodeKey) throw new Error("[Tree] nodeKey is required in setCheckedNodes");
        this.store.setCheckedNodes(nodes, leafOnly);
      }
    }, {
      key: "setCheckedKeys",
      value: function setCheckedKeys(keys, leafOnly) {
        if (!this.props.nodeKey) throw new Error("[Tree] nodeKey is required in setCheckedNodes");
        this.store.setCheckedKeys(keys, leafOnly);
      }
    }, {
      key: "setChecked",
      value: function setChecked(data, checked, deep) {
        this.store.setChecked(data, checked, deep);
      } // used by child nodes, use tree store to store this info?

    }, {
      key: "getCurrentNode",
      value: function getCurrentNode() {
        return this.state.currentNode;
      }
    }, {
      key: "setCurrentNode",
      value: function setCurrentNode(node) {
        (0, _utils.require_condition)(node != null);
        var _props = this.props,
            onCurrentChange = _props.onCurrentChange,
            onNodeClicked = _props.onNodeClicked;
        this.store.setCurrentNode(node);
        this.setState({
          currentNode: node
        }, function () {
          var nodeModel = node.props.nodeModel;
          onCurrentChange(nodeModel.data, node);
          onNodeClicked(nodeModel.data, node);
        });
      }
    }, {
      key: "closeSiblings",
      value: function closeSiblings(exclude) {
        var accordion = this.props.accordion;
        if (!accordion) return;
        if (!this.root.childNodes || !this.root.childNodes.length) return;
        this.root.childNodes.filter(function (e) {
          return e !== exclude;
        }).forEach(function (e) {
          return e.collapse();
        });
        this.refresh();
      }
    }, {
      key: "render",
      value: function render() {
        var _this2 = this;

        var _props2 = this.props,
            options = _props2.options,
            renderContent = _props2.renderContent,
            highlightCurrent = _props2.highlightCurrent,
            isShowCheckbox = _props2.isShowCheckbox,
            onCheckChange = _props2.onCheckChange,
            onNodeClicked = _props2.onNodeClicked,
            emptyText = _props2.emptyText;

        var renderEmptyText = function renderEmptyText() {
          if (!_this2.root.childNodes || _this2.root.childNodes.length === 0) {
            return _react2.default.createElement("div", {
              className: "el-tree__empty-block"
            }, _react2.default.createElement("span", {
              className: "el-tree__empty-text"
            }, emptyText));
          } else return null;
        };

        return _react2.default.createElement("div", {
          style: this.style(),
          className: this.className("el-tree", {
            "el-tree--highlight-current": highlightCurrent
          })
        }, this.root.childNodes.map(function (e, idx) {
          return _react2.default.createElement(_Node2.default, {
            ref: "cnode",
            key: _this2.getNodeKey(e, idx),
            nodeModel: e,
            options: options,
            renderContent: renderContent,
            treeNode: _this2,
            parent: _this2,
            isShowCheckbox: isShowCheckbox,
            onCheckChange: onCheckChange
          });
        }), renderEmptyText());
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }, {
      key: "root",
      get: function get() {
        return this.state.store.root;
      }
    }, {
      key: "store",
      get: function get() {
        return this.state.store;
      }
    }]);
    return Tree;
  }(_libs.Component);

  var _default = Tree;
  exports.default = _default;
  Tree.propTypes = {
    autoExpandParent: _libs.PropTypes.bool,
    checkStrictly: _libs.PropTypes.bool,
    currentNodeKey: _libs.PropTypes.any,
    defaultCheckedKeys: _libs.PropTypes.array,
    defaultExpandedKeys: _libs.PropTypes.array,
    defaultExpandAll: _libs.PropTypes.bool,
    data: _libs.PropTypes.array,
    emptyText: _libs.PropTypes.string,
    expandOnClickNode: _libs.PropTypes.bool,
    filterNodeMethod: _libs.PropTypes.func,
    renderContent: _libs.PropTypes.func,
    isShowCheckbox: _libs.PropTypes.bool,
    accordion: _libs.PropTypes.bool,
    indent: _libs.PropTypes.number,
    nodeKey: _libs.PropTypes.string,
    options: _libs.PropTypes.shape({
      children: _libs.PropTypes.string,
      label: _libs.PropTypes.string,
      icon: _libs.PropTypes.string
    }),
    //equal to props in vue element
    lazy: _libs.PropTypes.bool,
    //todo: check this
    highlightCurrent: _libs.PropTypes.bool,
    // (f:(resolve, reject)=>Unit)=>Unit
    load: _libs.PropTypes.func,
    //
    onCheckChange: _libs.PropTypes.func,
    // todo: 这个地方需要改下， 现在是current和nodeclick一起被设置上了
    // (nodeModel.data, node)=>Unit
    onNodeClicked: _libs.PropTypes.func,
    // (nodeModel.data, node)=>Unit
    onCurrentChange: _libs.PropTypes.func,
    // (nodeModel.data, nodeModel, Node)=>Unit
    onNodeExpand: _libs.PropTypes.func,
    onNodeCollapse: _libs.PropTypes.func
  };
  Tree.defaultProps = {
    autoExpandParent: true,
    defaultCheckedKeys: [],
    defaultExpandedKeys: [],
    data: [],
    expandOnClickNode: true,
    emptyText: _locale2.default.t("el.tree.emptyText"),
    indent: 16,
    options: {
      children: "children",
      label: "label",
      icon: "icon"
    },
    onCheckChange: function onCheckChange() {},
    onNodeClicked: function onNodeClicked() {},
    onCurrentChange: function onCurrentChange() {},
    onNodeExpand: function onNodeExpand() {},
    onNodeCollapse: function onNodeCollapse() {}
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Tree, "Tree", "src/tree/Tree.jsx");
    reactHotLoader.register(_default, "default", "src/tree/Tree.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}