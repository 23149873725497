import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var isDragging = false;

  var _default = function _default(element, options) {
    var moveFn = function moveFn(event) {
      if (options.drag) {
        options.drag(event);
      }
    };

    var upFn = function upFn(event) {
      document.removeEventListener("mousemove", moveFn);
      document.removeEventListener("mouseup", upFn);
      document.onselectstart = null;
      document.ondragstart = null;
      isDragging = false;

      if (options.end) {
        options.end(event);
      }
    };

    element.addEventListener("mousedown", function (event) {
      if (isDragging) return;

      document.onselectstart = function () {
        return false;
      };

      document.ondragstart = function () {
        return false;
      };

      document.addEventListener("mousemove", moveFn);
      document.addEventListener("mouseup", upFn);
      isDragging = true;

      if (options.start) {
        options.start(event);
      }
    });
  };

  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(isDragging, "isDragging", "src/color-picker/draggable.js");
    reactHotLoader.register(_default, "default", "src/color-picker/draggable.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}