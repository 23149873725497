import { dew as _Dropdown3Dew } from "./Dropdown";
import { dew as _DropdownMenu3Dew } from "./DropdownMenu";
import { dew as _DropdownItem3Dew } from "./DropdownItem";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Dropdown = _Dropdown3Dew();

  var _Dropdown2 = _interopRequireDefault(_Dropdown);

  var _DropdownMenu = _DropdownMenu3Dew();

  var _DropdownMenu2 = _interopRequireDefault(_DropdownMenu);

  var _DropdownItem = _DropdownItem3Dew();

  var _DropdownItem2 = _interopRequireDefault(_DropdownItem);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  _Dropdown2.default.Item = _DropdownItem2.default;
  _Dropdown2.default.Menu = _DropdownMenu2.default;
  var _default = _Dropdown2.default;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(_default, "default", "src/dropdown/index.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}