import _extends from "babel-runtime/helpers/extends";
import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _get from "babel-runtime/helpers/get";
import _createClass from "babel-runtime/helpers/createClass";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _BasePicker4Dew } from "./BasePicker";
import { dew as _TimeSelectPanel3Dew } from "./panel/TimeSelectPanel";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _extends2 = _extends;

  var _extends3 = _interopRequireDefault(_extends2);

  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _get2 = _get;

  var _get3 = _interopRequireDefault(_get2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _BasePicker2 = _BasePicker4Dew();

  var _BasePicker3 = _interopRequireDefault(_BasePicker2);

  var _TimeSelectPanel = _TimeSelectPanel3Dew();

  var _TimeSelectPanel2 = _interopRequireDefault(_TimeSelectPanel);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var TimeSelect = function (_BasePicker) {
    (0, _inherits3.default)(TimeSelect, _BasePicker);
    (0, _createClass3.default)(TimeSelect, null, [{
      key: "propTypes",
      get: function get() {
        var result = Object.assign({}, {
          start: _libs.PropTypes.string,
          end: _libs.PropTypes.string,
          step: _libs.PropTypes.string,
          minTime: _libs.PropTypes.instanceOf(Date)
        }, _BasePicker3.default.propTypes);
        return result;
      }
    }, {
      key: "defaultProps",
      get: function get() {
        var result = Object.assign({}, _BasePicker3.default.defaultProps);
        return result;
      }
    }]);

    function TimeSelect(props) {
      (0, _classCallCheck3.default)(this, TimeSelect); // props, type, state

      return (0, _possibleConstructorReturn3.default)(this, (TimeSelect.__proto__ || Object.getPrototypeOf(TimeSelect)).call(this, props, "timeselect", {}));
    }

    (0, _createClass3.default)(TimeSelect, [{
      key: "isDateValid",
      value: function isDateValid(value) {
        return (0, _get3.default)(TimeSelect.prototype.__proto__ || Object.getPrototypeOf(TimeSelect.prototype), "isDateValid", this).call(this, value) && _TimeSelectPanel2.default.isValid(this.dateToStr(value), this.panelProps());
      }
    }, {
      key: "panelProps",
      value: function panelProps(props) {
        var ps = props || this.props;
        var minTime = this.dateToStr(this.props.minTime);
        return (0, _extends3.default)({}, ps, {
          minTime: minTime
        });
      }
    }, {
      key: "pickerPanel",
      value: function pickerPanel(state, props) {
        var _this2 = this;

        var value = this.dateToStr(state.value);
        return _react2.default.createElement(_TimeSelectPanel2.default, (0, _extends3.default)({}, this.panelProps(props), {
          value: value,
          onPicked: this.onPicked.bind(this),
          dateParser: function dateParser(str) {
            var r = _this2.parseDate(str);

            return r;
          }
        }));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return TimeSelect;
  }(_BasePicker3.default);

  var _default = TimeSelect;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(TimeSelect, "TimeSelect", "src/date-picker/TimeSelect.jsx");
    reactHotLoader.register(_default, "default", "src/date-picker/TimeSelect.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}