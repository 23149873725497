import { dew as _EventRegister2Dew } from "./EventRegister";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _EventRegister = _EventRegister2Dew();

  Object.defineProperty(exports, "EventRegister", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_EventRegister).default;
    }
  });

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  return exports;
}