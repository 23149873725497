import _typeof from "babel-runtime/helpers/typeof";
import _react3 from "react";
import _reactDom3 from "react-dom";
import { dew as _MessageBox3Dew } from "./MessageBox";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _typeof2 = _typeof;

  var _typeof3 = _interopRequireDefault(_typeof2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _reactDom = _reactDom3;

  var _reactDom2 = _interopRequireDefault(_reactDom);

  var _MessageBox = _MessageBox3Dew();

  var _MessageBox2 = _interopRequireDefault(_MessageBox);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  function alert(message, title, props) {
    if ((typeof title === "undefined" ? "undefined" : (0, _typeof3.default)(title)) === "object") {
      props = title;
    }

    props = Object.assign({
      title: title,
      message: message,
      modal: "alert",
      closeOnPressEscape: false,
      closeOnClickModal: false
    }, props);
    return next(props);
  }

  function confirm(message, title, props) {
    if ((typeof title === "undefined" ? "undefined" : (0, _typeof3.default)(title)) === "object") {
      props = title;
    }

    props = Object.assign({
      title: title,
      message: message,
      modal: "confirm",
      showCancelButton: true
    }, props);
    return next(props);
  }

  function prompt(message, title, props) {
    if ((typeof title === "undefined" ? "undefined" : (0, _typeof3.default)(title)) === "object") {
      props = title;
    }

    props = Object.assign({
      title: title,
      message: message,
      modal: "prompt",
      showCancelButton: true,
      showInput: true
    }, props);
    return next(props);
  }

  function msgbox(props) {
    return next(props);
  }

  function next(props) {
    return new Promise(function (resolve, reject) {
      var div = document.createElement("div");
      document.body.appendChild(div);

      if (props.lockScroll != false) {
        document.body.style.setProperty("overflow", "hidden");
      }

      var component = _react2.default.createElement(_MessageBox2.default, Object.assign({}, props, {
        promise: {
          resolve: resolve,
          reject: reject
        },
        willUnmount: function willUnmount() {
          _reactDom2.default.unmountComponentAtNode(div);

          document.body.removeChild(div);
          document.body.style.removeProperty("overflow");
        }
      }));

      _reactDom2.default.render(component, div);
    });
  }

  var _default = {
    alert: alert,
    confirm: confirm,
    prompt: prompt,
    msgbox: msgbox
  };
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(alert, "alert", "src/message-box/index.js");
    reactHotLoader.register(confirm, "confirm", "src/message-box/index.js");
    reactHotLoader.register(prompt, "prompt", "src/message-box/index.js");
    reactHotLoader.register(msgbox, "msgbox", "src/message-box/index.js");
    reactHotLoader.register(next, "next", "src/message-box/index.js");
    reactHotLoader.register(_default, "default", "src/message-box/index.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}