import _extends from "babel-runtime/helpers/extends";
import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _UploadList3Dew } from "./UploadList";
import { dew as _iFrameUpload3Dew } from "./iFrameUpload";
import { dew as _AjaxUpload3Dew } from "./AjaxUpload";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _extends2 = _extends;

  var _extends3 = _interopRequireDefault(_extends2);

  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _UploadList = _UploadList3Dew();

  var _UploadList2 = _interopRequireDefault(_UploadList);

  var _iFrameUpload = _iFrameUpload3Dew();

  var _iFrameUpload2 = _interopRequireDefault(_iFrameUpload);

  var _AjaxUpload = _AjaxUpload3Dew();

  var _AjaxUpload2 = _interopRequireDefault(_AjaxUpload);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Upload = function (_Component) {
    (0, _inherits3.default)(Upload, _Component);

    function Upload(props) {
      (0, _classCallCheck3.default)(this, Upload);

      var _this = (0, _possibleConstructorReturn3.default)(this, (Upload.__proto__ || Object.getPrototypeOf(Upload)).call(this, props));

      _this.state = {
        fileList: [],
        tempIndex: 1
      };
      return _this;
    }

    (0, _createClass3.default)(Upload, [{
      key: "componentWillMount",
      value: function componentWillMount() {
        this.init(this.props);
      }
    }, {
      key: "init",
      value: function init(props) {
        var tempIndex = this.state.tempIndex;
        var fileList = props.fileList;
        var uploadFiles = fileList.map(function (file) {
          file.uid = file.uid || Date.now() + tempIndex++;
          file.status = "success";
          return file;
        });
        this.setState({
          fileList: uploadFiles
        });
      }
    }, {
      key: "getChildContext",
      value: function getChildContext() {
        return {
          onPreview: this.handlePreview.bind(this),
          onRemove: this.handleRemove.bind(this)
        };
      }
    }, {
      key: "getFile",
      value: function getFile(file) {
        if (file) {
          return this.state.fileList.find(function (item) {
            return item.uid === file.uid;
          });
        }

        return null;
      }
    }, {
      key: "handleStart",
      value: function handleStart(file) {
        var _state = this.state,
            tempIndex = _state.tempIndex,
            fileList = _state.fileList;
        file.uid = Date.now() + tempIndex++;
        var _file = {
          status: "ready",
          name: file.name,
          size: file.size,
          percentage: 0,
          uid: file.uid,
          raw: file
        };

        try {
          _file.url = URL.createObjectURL(file);
        } catch (err) {
          return;
        }

        fileList.push(_file);
        this.setState({
          fileList: fileList,
          tempIndex: tempIndex
        });
      }
    }, {
      key: "handleProgress",
      value: function handleProgress(e, file) {
        var fileList = this.state.fileList;

        var _file = this.getFile(file);

        if (_file) {
          _file.percentage = e.percent || 0;
          _file.status = "uploading";
          this.props.onProgress(e, _file, fileList);
          this.setState({
            fileList: fileList
          });
        }
      }
    }, {
      key: "handleSuccess",
      value: function handleSuccess(res, file) {
        var _this2 = this;

        var fileList = this.state.fileList;

        var _file = this.getFile(file);

        if (_file) {
          _file.status = "success";
          _file.response = res;
          setTimeout(function () {
            _this2.setState({
              fileList: fileList
            }, function () {
              _this2.props.onSuccess(res, _file, fileList);

              _this2.props.onChange(_file, fileList);
            });
          }, 1000);
        }
      }
    }, {
      key: "handleError",
      value: function handleError(err, file) {
        var _this3 = this;

        var fileList = this.state.fileList;

        var _file = this.getFile(file);

        if (_file) {
          _file.status = "fail";
          fileList.splice(fileList.indexOf(_file), 1);
          this.setState({
            fileList: fileList
          }, function () {
            _this3.props.onError(err, _file, fileList);

            _this3.props.onChange(_file, fileList);
          });
        }
      }
    }, {
      key: "handleRemove",
      value: function handleRemove(file) {
        var _this4 = this;

        var fileList = this.state.fileList;

        var _file = this.getFile(file);

        if (_file) {
          fileList.splice(fileList.indexOf(_file), 1);
          this.setState({
            fileList: fileList
          }, function () {
            return _this4.props.onRemove(file, fileList);
          });
        }
      }
    }, {
      key: "handlePreview",
      value: function handlePreview(file) {
        if (file.status === "success") {
          this.props.onPreview(file);
        }
      }
    }, {
      key: "clearFiles",
      value: function clearFiles() {
        this.setState({
          fileList: []
        });
      }
    }, {
      key: "submit",
      value: function submit() {
        var _this5 = this;

        this.state.fileList.filter(function (file) {
          return file.status === "ready";
        }).forEach(function (file) {
          _this5.refs["upload-inner"].upload(file.raw, file);
        });
      }
    }, {
      key: "showCover",
      value: function showCover() {
        var fileList = this.state.fileList;
        var file = fileList[fileList.length - 1];
        return file && file.status !== "fail";
      }
    }, {
      key: "render",
      value: function render() {
        var fileList = this.state.fileList;
        var _props = this.props,
            showFileList = _props.showFileList,
            autoUpload = _props.autoUpload,
            drag = _props.drag,
            tip = _props.tip,
            action = _props.action,
            multiple = _props.multiple,
            beforeUpload = _props.beforeUpload,
            withCredentials = _props.withCredentials,
            headers = _props.headers,
            name = _props.name,
            data = _props.data,
            accept = _props.accept,
            listType = _props.listType,
            className = _props.className,
            limit = _props.limit,
            disabled = _props.disabled,
            onExceed = _props.onExceed,
            httpRequest = _props.httpRequest;
        var uploadList = void 0;

        if (showFileList && fileList.length) {
          uploadList = _react2.default.createElement(_UploadList2.default, {
            listType: listType,
            fileList: fileList
          });
        }

        var restProps = {
          autoUpload: autoUpload,
          drag: drag,
          action: action,
          multiple: multiple,
          beforeUpload: beforeUpload,
          withCredentials: withCredentials,
          headers: headers,
          name: name,
          data: data,
          accept: accept,
          listType: listType,
          fileList: fileList,
          limit: limit,
          disabled: disabled,
          onExceed: onExceed,
          httpRequest: httpRequest,
          onStart: this.handleStart.bind(this),
          onProgress: this.handleProgress.bind(this),
          onSuccess: this.handleSuccess.bind(this),
          onError: this.handleError.bind(this),
          onPreview: this.handlePreview.bind(this),
          onRemove: this.handleRemove.bind(this),
          showCover: this.showCover(),
          ref: "upload-inner"
        };
        var trigger = this.props.trigger || this.props.children;
        var uploadComponent = typeof FormData !== "undefined" ? _react2.default.createElement(_AjaxUpload2.default, (0, _extends3.default)({
          key: "AjaxUpload"
        }, restProps), trigger) : _react2.default.createElement("iFrameUpload", (0, _extends3.default)({
          key: "iFrameUpload"
        }, restProps), trigger);
        return _react2.default.createElement("div", {
          className: className
        }, listType === "picture-card" ? uploadList : "", this.props.trigger ? [uploadComponent, this.props.children] : uploadComponent, tip, listType !== "picture-card" ? uploadList : "");
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return Upload;
  }(_libs.Component);

  Upload.defaultProps = {
    headers: {},
    name: "file",
    type: "select",
    listType: "text",
    fileList: [],
    showFileList: true,
    autoUpload: true,
    disabled: false,
    onRemove: function onRemove() {},
    onPreview: function onPreview() {},
    onProgress: function onProgress() {},
    onSuccess: function onSuccess() {},
    onError: function onError() {},
    onChange: function onChange() {}
  };
  var _default = Upload;
  exports.default = _default;
  Upload.childContextTypes = {
    onPreview: _libs.PropTypes.func,
    onRemove: _libs.PropTypes.func
  };
  Upload.propTypes = {
    action: _libs.PropTypes.string.isRequired,
    headers: _libs.PropTypes.object,
    data: _libs.PropTypes.object,
    multiple: _libs.PropTypes.bool,
    name: _libs.PropTypes.string,
    withCredentials: _libs.PropTypes.bool,
    showFileList: _libs.PropTypes.bool,
    fileList: _libs.PropTypes.array,
    autoUpload: _libs.PropTypes.bool,
    accept: _libs.PropTypes.string,
    drag: _libs.PropTypes.bool,
    listType: _libs.PropTypes.oneOf(["text", "picture", "picture-card"]),
    tip: _libs.PropTypes.node,
    trigger: _libs.PropTypes.node,
    beforeUpload: _libs.PropTypes.func,
    onRemove: _libs.PropTypes.func,
    onPreview: _libs.PropTypes.func,
    onProgress: _libs.PropTypes.func,
    onSuccess: _libs.PropTypes.func,
    onError: _libs.PropTypes.func,
    onChange: _libs.PropTypes.func,
    className: _libs.PropTypes.string,
    disabled: _libs.PropTypes.bool,
    limit: _libs.PropTypes.number,
    onExceed: _libs.PropTypes.func,
    httpRequest: _libs.PropTypes.func
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Upload, "Upload", "src/upload/Upload.jsx");
    reactHotLoader.register(_default, "default", "src/upload/Upload.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}