import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react2 from "react";
import _reactDom3 from "react-dom";
import _popper3 from "popper.js";
import { dew as _libs2Dew } from "../../libs";
import { dew as _checkbox3Dew } from "../checkbox";
import { dew as _Types2Dew } from "./Types";
import { dew as _locale3Dew } from "../locale";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react2;

  var React = _interopRequireWildcard(_react);

  var _reactDom = _reactDom3;

  var _reactDom2 = _interopRequireDefault(_reactDom);

  var _popper = _popper3;

  var _popper2 = _interopRequireDefault(_popper);

  var _libs = _libs2Dew();

  var _checkbox = _checkbox3Dew();

  var _checkbox2 = _interopRequireDefault(_checkbox);

  var _Types = _Types2Dew();

  var _locale = _locale3Dew();

  var _locale2 = _interopRequireDefault(_locale);

  function _interopRequireWildcard(obj) {
    if (obj && obj.__esModule) {
      return obj;
    } else {
      var newObj = {};

      if (obj != null) {
        for (var key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
        }
      }

      newObj.default = obj;
      return newObj;
    }
  }

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  function getPopupContainer() {
    var container = document.createElement("div");
    container.className = "el-table-poper";
    container.style.zIndex = 999;
    document.body.appendChild(container);
    return container;
  }

  var FilterPannel = function (_Component) {
    (0, _inherits3.default)(FilterPannel, _Component);

    function FilterPannel(props) {
      (0, _classCallCheck3.default)(this, FilterPannel);

      var _this = (0, _possibleConstructorReturn3.default)(this, (FilterPannel.__proto__ || Object.getPrototypeOf(FilterPannel)).call(this, props));

      _this.container = getPopupContainer();
      ["handleClickOutside", "onEnter", "onAfterLeave"].forEach(function (fn) {
        _this[fn] = _this[fn].bind(_this);
      });
      _this.state = {
        filteredValue: props.filteredValue
      };
      return _this;
    }

    (0, _createClass3.default)(FilterPannel, [{
      key: "componentDidMount",
      value: function componentDidMount() {
        this.renderPortal(this.renderContent(), this.container);
        document.addEventListener("click", this.handleClickOutside);
      }
    }, {
      key: "componentWillReceiveProps",
      value: function componentWillReceiveProps(nextProps) {
        if (this.props.filteredValue !== nextProps.filteredValue) {
          this.setState({
            filteredValue: nextProps.filteredValue
          });
        }
      }
    }, {
      key: "componentDidUpdate",
      value: function componentDidUpdate() {
        this.renderPortal(this.renderContent(), this.container);
      }
    }, {
      key: "componentWillUnmount",
      value: function componentWillUnmount() {
        this.poperIns && this.poperIns.destroy();

        _reactDom2.default.unmountComponentAtNode(this.container);

        document.removeEventListener("click", this.handleClickOutside);
        document.body.removeChild(this.container);
      }
    }, {
      key: "handleFiltersChange",
      value: function handleFiltersChange(value) {
        this.setState({
          filteredValue: value
        });
      }
    }, {
      key: "changeFilteredValue",
      value: function changeFilteredValue(value) {
        this.props.onFilterChange(value);
        this.props.toggleFilter();
      }
    }, {
      key: "handleClickOutside",
      value: function handleClickOutside() {
        if (this.props.visible) {
          this.props.toggleFilter();
        }
      }
    }, {
      key: "onEnter",
      value: function onEnter() {
        this.poperIns = new _popper2.default(this.refer, this.container, {
          placement: this.props.placement
        });
      }
    }, {
      key: "onAfterLeave",
      value: function onAfterLeave() {
        this.poperIns.destroy();
      }
    }, {
      key: "renderPortal",
      value: function renderPortal(element, container) {
        _reactDom2.default.render(element, container);
      }
    }, {
      key: "renderContent",
      value: function renderContent() {
        var _this2 = this;

        var _props = this.props,
            multiple = _props.multiple,
            filters = _props.filters,
            visible = _props.visible;
        var filteredValue = this.state.filteredValue;
        var content = void 0;

        if (multiple) {
          content = [React.createElement("div", {
            className: "el-table-filter__content",
            key: "content"
          }, React.createElement(_checkbox2.default.Group, {
            value: filteredValue || [],
            onChange: this.handleFiltersChange.bind(this),
            className: "el-table-filter__checkbox-group"
          }, filters && filters.map(function (filter) {
            return React.createElement(_checkbox2.default, {
              value: filter.value,
              label: filter.text,
              key: filter.value
            });
          }))), React.createElement("div", {
            className: "el-table-filter__bottom",
            key: "bottom"
          }, React.createElement("button", {
            className: this.classNames({
              "is-disabled": !filteredValue || !filteredValue.length
            }),
            disabled: !filteredValue || !filteredValue.length,
            onClick: this.changeFilteredValue.bind(this, filteredValue)
          }, _locale2.default.t("el.table.confirmFilter")), React.createElement("button", {
            onClick: this.changeFilteredValue.bind(this, null)
          }, _locale2.default.t("el.table.resetFilter")))];
        } else {
          content = React.createElement("ul", {
            className: "el-table-filter__list"
          }, React.createElement("li", {
            className: this.classNames("el-table-filter__list-item", {
              "is-active": !filteredValue
            }),
            onClick: this.changeFilteredValue.bind(this, null)
          }, _locale2.default.t("el.table.clearFilter")), filters && filters.map(function (filter) {
            return React.createElement("li", {
              key: filter.value,
              className: _this2.classNames("el-table-filter__list-item", {
                "is-active": filter.value === filteredValue
              }),
              onClick: _this2.changeFilteredValue.bind(_this2, filter.value)
            }, filter.text);
          }));
        }

        return React.createElement(_libs.Transition, {
          name: "el-zoom-in-top",
          onEnter: this.onEnter,
          onAfterLeave: this.onAfterLeave
        }, React.createElement(_libs.View, {
          show: visible
        }, React.createElement("div", {
          className: "el-table-filter",
          ref: function ref(dom) {
            _this2.poper = dom;
          },
          onClick: function onClick(e) {
            e.nativeEvent.stopImmediatePropagation();
          } // prevent document click event

        }, content)));
      }
    }, {
      key: "render",
      value: function render() {
        var _this3 = this;

        return React.cloneElement(this.props.children, {
          ref: function ref(dom) {
            _this3.refer = dom;
          }
        });
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return FilterPannel;
  }(_libs.Component);

  var _default = FilterPannel;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(getPopupContainer, "getPopupContainer", "src/table/FilterPannel.jsx");
    reactHotLoader.register(FilterPannel, "FilterPannel", "src/table/FilterPannel.jsx");
    reactHotLoader.register(_default, "default", "src/table/FilterPannel.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}