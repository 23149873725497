import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import _throttleDebounce2 from "throttle-debounce";
import { dew as _libs2Dew } from "../../libs";
import { dew as _resizeEvent2Dew } from "../../libs/utils/resize-event";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _throttleDebounce = _throttleDebounce2;

  var _libs = _libs2Dew();

  var _resizeEvent = _resizeEvent2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Carousel = function (_Component) {
    (0, _inherits3.default)(Carousel, _Component);

    function Carousel(props) {
      (0, _classCallCheck3.default)(this, Carousel);

      var _this = (0, _possibleConstructorReturn3.default)(this, (Carousel.__proto__ || Object.getPrototypeOf(Carousel)).call(this, props));

      _this.state = {
        items: [],
        activeIndex: -1,
        containerWidth: 0,
        timer: null,
        hover: false
      };
      _this.throttledArrowClick = (0, _throttleDebounce.throttle)(300, true, function (index) {
        _this.setActiveItem(index);
      });
      _this.throttledIndicatorHover = (0, _throttleDebounce.throttle)(300, function (index) {
        _this.handleIndicatorHover(index);
      });
      _this.resetItemPosition = _this._resetItemPosition.bind(_this);
      return _this;
    }

    (0, _createClass3.default)(Carousel, [{
      key: "getChildContext",
      value: function getChildContext() {
        return {
          component: this
        };
      }
    }, {
      key: "componentDidMount",
      value: function componentDidMount() {
        if (this.props.initialIndex < this.state.items.length && this.props.initialIndex >= 0) {
          this.setState({
            activeIndex: this.props.initialIndex
          });
        }

        this.startTimer();
      }
    }, {
      key: "componentDidUpdate",
      value: function componentDidUpdate(props, state) {
        (0, _resizeEvent.addResizeListener)(this.refs.root, this.resetItemPosition);

        if (state.activeIndex != this.state.activeIndex) {
          this.resetItemPosition(state.activeIndex);

          if (this.props.onChange) {
            this.props.onChange(this.state.activeIndex, state.activeIndex);
          }
        }
      }
    }, {
      key: "componentWillUnmount",
      value: function componentWillUnmount() {
        (0, _resizeEvent.removeResizeListener)(this.refs.root, this.resetItemPosition);
        this.pauseTimer();
      }
    }, {
      key: "handleMouseEnter",
      value: function handleMouseEnter() {
        this.setState({
          hover: true
        });
        this.pauseTimer();
      }
    }, {
      key: "handleMouseLeave",
      value: function handleMouseLeave() {
        this.setState({
          hover: false
        });
        this.startTimer();
      }
    }, {
      key: "itemInStage",
      value: function itemInStage(item, index) {
        var length = this.state.items.length;

        if (index === length - 1 && item.state.inStage && this.state.items[0].state.active || item.state.inStage && this.state.items[index + 1] && this.state.items[index + 1].state.active) {
          return "left";
        } else if (index === 0 && item.state.inStage && this.state.items[length - 1].state.active || item.state.inStage && this.state.items[index - 1] && this.state.items[index - 1].state.active) {
          return "right";
        }

        return false;
      }
    }, {
      key: "handleButtonEnter",
      value: function handleButtonEnter(arrow) {
        var _this2 = this;

        this.state.items.forEach(function (item, index) {
          if (arrow === _this2.itemInStage(item, index)) {
            item.setState({
              hover: true
            });
          }
        });
      }
    }, {
      key: "handleButtonLeave",
      value: function handleButtonLeave() {
        this.state.items.forEach(function (item) {
          item.setState({
            hover: false
          });
        });
      }
    }, {
      key: "_resetItemPosition",
      value: function _resetItemPosition(oldIndex) {
        var _this3 = this;

        this.state.items.forEach(function (item, index) {
          item.translateItem(index, _this3.state.activeIndex, oldIndex);
        });
      }
    }, {
      key: "playSlides",
      value: function playSlides() {
        var activeIndex = this.state.activeIndex;

        if (activeIndex < this.state.items.length - 1) {
          activeIndex++;
        } else {
          activeIndex = 0;
        }

        this.setState({
          activeIndex: activeIndex
        });
      }
    }, {
      key: "pauseTimer",
      value: function pauseTimer() {
        clearInterval(this.timer);
      }
    }, {
      key: "startTimer",
      value: function startTimer() {
        if (this.props.interval <= 0 || !this.props.autoplay) return;
        this.timer = setInterval(this.playSlides.bind(this), Number(this.props.interval));
      }
    }, {
      key: "addItem",
      value: function addItem(item) {
        this.state.items.push(item);
        this.setActiveItem(0);
      }
    }, {
      key: "removeItem",
      value: function removeItem(item) {
        this.state.items.splice(this.state.items.indexOf(item), 1);
        this.setActiveItem(0);
      }
    }, {
      key: "setActiveItem",
      value: function setActiveItem(index) {
        var activeIndex = this.state.activeIndex;

        if (typeof index === "string") {
          var filteredItems = this.state.items.filter(function (item) {
            return item.props.name === index;
          });

          if (filteredItems.length > 0) {
            index = this.state.items.indexOf(filteredItems[0]);
          }
        }

        index = Number(index);

        if (isNaN(index) || index !== Math.floor(index)) {
          false;
          return;
        }

        var length = this.state.items.length;

        if (index < 0) {
          activeIndex = length - 1;
        } else if (index >= length) {
          activeIndex = 0;
        } else {
          activeIndex = index;
        }

        this.setState({
          activeIndex: activeIndex
        });
      }
    }, {
      key: "prev",
      value: function prev() {
        this.setActiveItem(this.state.activeIndex - 1);
      }
    }, {
      key: "next",
      value: function next() {
        this.setActiveItem(this.state.activeIndex + 1);
      }
    }, {
      key: "handleIndicatorClick",
      value: function handleIndicatorClick(index) {
        this.setState({
          activeIndex: index
        });
      }
    }, {
      key: "handleIndicatorHover",
      value: function handleIndicatorHover(index) {
        if (this.props.trigger === "hover" && index !== this.state.activeIndex) {
          this.setState({
            activeIndex: index
          });
        }
      }
    }, {
      key: "render",
      value: function render() {
        var _this4 = this;

        var _props = this.props,
            height = _props.height,
            arrow = _props.arrow,
            indicatorPosition = _props.indicatorPosition;
        var _state = this.state,
            hover = _state.hover,
            activeIndex = _state.activeIndex,
            items = _state.items;
        return _react2.default.createElement("div", {
          ref: "root",
          className: this.className("el-carousel", {
            "el-carousel--card": this.iscard
          }),
          onMouseEnter: this.handleMouseEnter.bind(this),
          onMouseLeave: this.handleMouseLeave.bind(this)
        }, _react2.default.createElement("div", {
          className: "el-carousel__container",
          style: {
            height: height
          }
        }, _react2.default.createElement(_libs.Transition, {
          name: "carousel-arrow-left"
        }, arrow !== "never" && _react2.default.createElement(_libs.View, {
          show: arrow === "always" || hover
        }, _react2.default.createElement("button", {
          className: "el-carousel__arrow el-carousel__arrow--left",
          onMouseEnter: this.handleButtonEnter.bind(this, "left"),
          onMouseLeave: this.handleButtonLeave.bind(this),
          onClick: this.throttledArrowClick.bind(this, activeIndex - 1)
        }, _react2.default.createElement("i", {
          className: "el-icon-arrow-left"
        })))), _react2.default.createElement(_libs.Transition, {
          name: "carousel-arrow-right"
        }, arrow !== "never" && _react2.default.createElement(_libs.View, {
          show: arrow === "always" || hover
        }, _react2.default.createElement("button", {
          className: "el-carousel__arrow el-carousel__arrow--right",
          onMouseEnter: this.handleButtonEnter.bind(this, "right"),
          onMouseLeave: this.handleButtonLeave.bind(this),
          onClick: this.throttledArrowClick.bind(this, activeIndex + 1)
        }, _react2.default.createElement("i", {
          className: "el-icon-arrow-right"
        })))), this.props.children), indicatorPosition !== "none" && _react2.default.createElement("ul", {
          className: this.classNames("el-carousel__indicators", {
            "el-carousel__indicators--outside": indicatorPosition === "outside" || this.iscard
          })
        }, items.map(function (item, index) {
          return _react2.default.createElement("li", {
            key: index,
            className: _this4.classNames("el-carousel__indicator", {
              "is-active": index === activeIndex
            }),
            onMouseEnter: _this4.throttledIndicatorHover.bind(_this4, index),
            onClick: _this4.handleIndicatorClick.bind(_this4, index)
          }, _react2.default.createElement("button", {
            className: "el-carousel__button"
          }));
        })));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }, {
      key: "iscard",
      get: function get() {
        var type = this.props.type;

        if (type) {
          return type === "card" || type === "flatcard";
        }

        return false;
      }
    }]);
    return Carousel;
  }(_libs.Component);

  var _default = Carousel;
  exports.default = _default;
  Carousel.childContextTypes = {
    component: _libs.PropTypes.any
  };
  Carousel.propTypes = {
    initialIndex: _libs.PropTypes.number,
    height: _libs.PropTypes.string,
    trigger: _libs.PropTypes.string,
    autoplay: _libs.PropTypes.bool,
    interval: _libs.PropTypes.oneOfType([_libs.PropTypes.number, _libs.PropTypes.string]),
    indicatorPosition: _libs.PropTypes.string,
    indicator: _libs.PropTypes.bool,
    arrow: _libs.PropTypes.string,
    type: _libs.PropTypes.oneOf(["card", "flatcard"]),
    onChange: _libs.PropTypes.func
  };
  Carousel.defaultProps = {
    initialIndex: 0,
    trigger: "hover",
    autoplay: true,
    interval: 3000,
    indicator: true,
    arrow: "hover"
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Carousel, "Carousel", "src/carousel/Carousel.jsx");
    reactHotLoader.register(_default, "default", "src/carousel/Carousel.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}