import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Steps = function (_Component) {
    (0, _inherits3.default)(Steps, _Component);

    function Steps() {
      (0, _classCallCheck3.default)(this, Steps);
      return (0, _possibleConstructorReturn3.default)(this, (Steps.__proto__ || Object.getPrototypeOf(Steps)).apply(this, arguments));
    }

    (0, _createClass3.default)(Steps, [{
      key: "calcProgress",
      value: function calcProgress(status, index) {
        var step = 100;
        var style = {};
        style.transitionDelay = 150 * index + "ms";
        var nextStatus = this.calStatus(index + 1); // 前后状态不一致时，并且当前status为完成，statusLine的长度才为50%

        if (nextStatus !== status) {
          if (status === this.props.finishStatus) {
            step = 50;
          } else if (status === "wait") {
            step = 0;
            style.transitionDelay = -150 * index + "ms";
          }
        }

        this.props.direction === "vertical" ? style.height = step + "%" : style.width = step + "%";
        return style;
      }
    }, {
      key: "calStatus",
      value: function calStatus(index) {
        var _props = this.props,
            active = _props.active,
            finishStatus = _props.finishStatus,
            processStatus = _props.processStatus;
        var status = "wait";

        if (active > index) {
          status = finishStatus;
        } else if (active === index) {
          status = processStatus;
        }

        return status;
      }
    }, {
      key: "render",
      value: function render() {
        var _this2 = this;

        var _props2 = this.props,
            children = _props2.children,
            space = _props2.space,
            direction = _props2.direction;
        return _react2.default.createElement("div", {
          style: this.style(),
          className: this.className("el-steps")
        }, _react2.default.Children.map(children, function (child, index) {
          var computedSpace = space ? space + "px" : 100 / children.length + "%";
          var style = direction === "horizontal" ? {
            width: computedSpace
          } : {
            height: index === children.length - 1 ? "auto" : computedSpace
          };

          var status = _this2.calStatus(index);

          var lineStyle = _this2.calcProgress(status, index);

          return _react2.default.cloneElement(child, {
            style: style,
            lineStyle: lineStyle,
            direction: direction,
            status: status,
            stepNumber: index + 1
          });
        }));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return Steps;
  }(_libs.Component);

  Steps.defaultProps = {
    direction: "horizontal",
    finishStatus: "finish",
    processStatus: "process",
    active: 0
  };
  var _default = Steps;
  exports.default = _default;
  var statusMap = ["wait", "process", "finish", "error", "success"];
  Steps.propTypes = {
    space: _libs.PropTypes.number,
    active: _libs.PropTypes.number,
    direction: _libs.PropTypes.oneOf(["vertical", "horizontal"]),
    finishStatus: _libs.PropTypes.oneOf(statusMap),
    processStatus: _libs.PropTypes.oneOf(statusMap)
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Steps, "Steps", "src/steps/Steps.jsx");
    reactHotLoader.register(statusMap, "statusMap", "src/steps/Steps.jsx");
    reactHotLoader.register(_default, "default", "src/steps/Steps.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}