import { dew as _locale2Dew } from "./locale";
import { dew as _alert2Dew } from "./alert";
import { dew as _button2Dew } from "./button";
import { dew as _card2Dew } from "./card";
import { dew as _layout2Dew } from "./layout";
import { dew as _loading2Dew } from "./loading";
import { dew as _message2Dew } from "./message";
import { dew as _messageBox2Dew } from "./message-box";
import { dew as _notification2Dew } from "./notification";
import { dew as _radio2Dew } from "./radio";
import { dew as _dialog2Dew } from "./dialog";
import { dew as _rate2Dew } from "./rate";
import { dew as _progress2Dew } from "./progress";
import { dew as _badge2Dew } from "./badge";
import { dew as _tabs2Dew } from "./tabs";
import { dew as _tree2Dew } from "./tree";
import { dew as _input2Dew } from "./input";
import { dew as _icon2Dew } from "./icon";
import { dew as _menu2Dew } from "./menu";
import { dew as _steps2Dew } from "./steps";
import { dew as _breadcrumb2Dew } from "./breadcrumb";
import { dew as _tooltip2Dew } from "./tooltip";
import { dew as _inputNumber2Dew } from "./input-number";
import { dew as _checkbox2Dew } from "./checkbox";
import { dew as _slider2Dew } from "./slider";
import { dew as _table2Dew } from "./table";
import { dew as _switch2Dew } from "./switch";
import { dew as _form2Dew } from "./form";
import { dew as _upload2Dew } from "./upload";
import { dew as _tag2Dew } from "./tag";
import { dew as _select2Dew } from "./select";
import { dew as _dropdown2Dew } from "./dropdown";
import { dew as _popover2Dew } from "./popover";
import { dew as _pagination2Dew } from "./pagination";
import { dew as _autoComplete2Dew } from "./auto-complete";
import { dew as _datePicker2Dew } from "./date-picker";
import { dew as _carousel2Dew } from "./carousel";
import { dew as _collapse2Dew } from "./collapse";
import { dew as _colorPicker2Dew } from "./color-picker";
import { dew as _cascader2Dew } from "./cascader";
import { dew as _transfer2Dew } from "./transfer";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _locale = _locale2Dew();

  Object.defineProperty(exports, "i18n", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_locale).default;
    }
  });

  var _alert = _alert2Dew();

  Object.defineProperty(exports, "Alert", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_alert).default;
    }
  });

  var _button = _button2Dew();

  Object.defineProperty(exports, "Button", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_button).default;
    }
  });

  var _card = _card2Dew();

  Object.defineProperty(exports, "Card", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_card).default;
    }
  });

  var _layout = _layout2Dew();

  Object.defineProperty(exports, "Layout", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_layout).default;
    }
  });

  var _loading = _loading2Dew();

  Object.defineProperty(exports, "Loading", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_loading).default;
    }
  });

  var _message = _message2Dew();

  Object.defineProperty(exports, "Message", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_message).default;
    }
  });

  var _messageBox = _messageBox2Dew();

  Object.defineProperty(exports, "MessageBox", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_messageBox).default;
    }
  });

  var _notification = _notification2Dew();

  Object.defineProperty(exports, "Notification", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_notification).default;
    }
  });

  var _radio = _radio2Dew();

  Object.defineProperty(exports, "Radio", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_radio).default;
    }
  });

  var _dialog = _dialog2Dew();

  Object.defineProperty(exports, "Dialog", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_dialog).default;
    }
  });

  var _rate = _rate2Dew();

  Object.defineProperty(exports, "Rate", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_rate).default;
    }
  });

  var _progress = _progress2Dew();

  Object.defineProperty(exports, "Progress", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_progress).default;
    }
  });

  var _badge = _badge2Dew();

  Object.defineProperty(exports, "Badge", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_badge).default;
    }
  });

  var _tabs = _tabs2Dew();

  Object.defineProperty(exports, "Tabs", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_tabs).default;
    }
  });

  var _tree = _tree2Dew();

  Object.defineProperty(exports, "Tree", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_tree).default;
    }
  });

  var _input = _input2Dew();

  Object.defineProperty(exports, "Input", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_input).default;
    }
  });

  var _icon = _icon2Dew();

  Object.defineProperty(exports, "Icon", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_icon).default;
    }
  });

  var _menu = _menu2Dew();

  Object.defineProperty(exports, "Menu", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_menu).default;
    }
  });

  var _steps = _steps2Dew();

  Object.defineProperty(exports, "Steps", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_steps).default;
    }
  });

  var _breadcrumb = _breadcrumb2Dew();

  Object.defineProperty(exports, "Breadcrumb", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_breadcrumb).default;
    }
  });

  var _tooltip = _tooltip2Dew();

  Object.defineProperty(exports, "Tooltip", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_tooltip).default;
    }
  });

  var _inputNumber = _inputNumber2Dew();

  Object.defineProperty(exports, "InputNumber", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_inputNumber).default;
    }
  });

  var _checkbox = _checkbox2Dew();

  Object.defineProperty(exports, "Checkbox", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_checkbox).default;
    }
  });

  var _slider = _slider2Dew();

  Object.defineProperty(exports, "Slider", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_slider).default;
    }
  });

  var _table = _table2Dew();

  Object.defineProperty(exports, "Table", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_table).default;
    }
  });

  var _switch = _switch2Dew();

  Object.defineProperty(exports, "Switch", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_switch).default;
    }
  });

  var _form = _form2Dew();

  Object.defineProperty(exports, "Form", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_form).default;
    }
  });

  var _upload = _upload2Dew();

  Object.defineProperty(exports, "Upload", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_upload).default;
    }
  });

  var _tag = _tag2Dew();

  Object.defineProperty(exports, "Tag", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_tag).default;
    }
  });

  var _select = _select2Dew();

  Object.defineProperty(exports, "Select", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_select).default;
    }
  });

  var _dropdown = _dropdown2Dew();

  Object.defineProperty(exports, "Dropdown", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_dropdown).default;
    }
  });

  var _popover = _popover2Dew();

  Object.defineProperty(exports, "Popover", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_popover).default;
    }
  });

  var _pagination = _pagination2Dew();

  Object.defineProperty(exports, "Pagination", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_pagination).default;
    }
  });

  var _autoComplete = _autoComplete2Dew();

  Object.defineProperty(exports, "AutoComplete", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_autoComplete).default;
    }
  });

  var _datePicker = _datePicker2Dew();

  Object.defineProperty(exports, "TimeSelect", {
    enumerable: true,
    get: function get() {
      return _datePicker.TimeSelect;
    }
  });
  Object.defineProperty(exports, "TimePicker", {
    enumerable: true,
    get: function get() {
      return _datePicker.TimePicker;
    }
  });
  Object.defineProperty(exports, "TimeRangePicker", {
    enumerable: true,
    get: function get() {
      return _datePicker.TimeRangePicker;
    }
  });
  Object.defineProperty(exports, "DatePicker", {
    enumerable: true,
    get: function get() {
      return _datePicker.DatePicker;
    }
  });
  Object.defineProperty(exports, "DateRangePicker", {
    enumerable: true,
    get: function get() {
      return _datePicker.DateRangePicker;
    }
  });

  var _carousel = _carousel2Dew();

  Object.defineProperty(exports, "Carousel", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_carousel).default;
    }
  });

  var _collapse = _collapse2Dew();

  Object.defineProperty(exports, "Collapse", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_collapse).default;
    }
  });

  var _colorPicker = _colorPicker2Dew();

  Object.defineProperty(exports, "ColorPicker", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_colorPicker).default;
    }
  });

  var _cascader = _cascader2Dew();

  Object.defineProperty(exports, "Cascader", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_cascader).default;
    }
  });

  var _transfer = _transfer2Dew();

  Object.defineProperty(exports, "Transfer", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_transfer).default;
    }
  });

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  return exports;
}