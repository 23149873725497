import _extends from "babel-runtime/helpers/extends";
import _toConsumableArray from "babel-runtime/helpers/toConsumableArray";
import _objectWithoutProperties from "babel-runtime/helpers/objectWithoutProperties";
import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import _reactDom3 from "react-dom";
import { dew as _libs2Dew } from "../../libs";
import { dew as _resizeEvent2Dew } from "../../libs/utils/resize-event";
import { dew as _scrollbarWidth2Dew } from "./scrollbar-width";
import { dew as _Bar2Dew } from "./Bar";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Scrollbar = undefined;
  var _extends2 = _extends;

  var _extends3 = _interopRequireDefault(_extends2);

  var _toConsumableArray2 = _toConsumableArray;

  var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

  var _objectWithoutProperties2 = _objectWithoutProperties;

  var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _reactDom = _reactDom3;

  var _reactDom2 = _interopRequireDefault(_reactDom);

  var _libs = _libs2Dew();

  var _resizeEvent = _resizeEvent2Dew();

  var _scrollbarWidth = _scrollbarWidth2Dew();

  var _Bar = _Bar2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Scrollbar = exports.Scrollbar = function (_Component) {
    (0, _inherits3.default)(Scrollbar, _Component);

    function Scrollbar(props) {
      (0, _classCallCheck3.default)(this, Scrollbar);

      var _this = (0, _possibleConstructorReturn3.default)(this, (Scrollbar.__proto__ || Object.getPrototypeOf(Scrollbar)).call(this, props));

      _this.state = {
        sizeWidth: "0",
        sizeHeight: "0",
        moveX: 0,
        moveY: 0
      };
      _this.update = _this._update.bind(_this);
      return _this;
    }

    (0, _createClass3.default)(Scrollbar, [{
      key: "componentDidMount",
      value: function componentDidMount() {
        if (this.native) return;
        var rafId = requestAnimationFrame(this.update);

        this.cleanRAF = function () {
          cancelAnimationFrame(rafId);
        };
      }
    }, {
      key: "componentDidUpdate",
      value: function componentDidUpdate() {
        var _this2 = this;

        this.resizeDom = _reactDom2.default.findDOMNode(this.refs.resize);

        if (!this.props.noresize) {
          this.cleanResize && this.cleanResize();
          (0, _resizeEvent.addResizeListener)(this.resizeDom, this.update);

          this.cleanResize = function () {
            (0, _resizeEvent.removeResizeListener)(_this2.resizeDom, _this2.update);
          };
        }
      }
    }, {
      key: "componentWillUnmount",
      value: function componentWillUnmount() {
        this.cleanRAF();
        this.cleanResize && this.cleanResize();
      }
    }, {
      key: "handleScroll",
      value: function handleScroll() {
        var wrap = this.wrap;
        this.setState({
          moveY: wrap.scrollTop * 100 / wrap.clientHeight,
          moveX: wrap.scrollLeft * 100 / wrap.clientWidth
        });
      }
    }, {
      key: "_update",
      value: function _update() {
        var heightPercentage = void 0,
            widthPercentage = void 0;
        var wrap = this.wrap;
        if (!wrap) return;
        heightPercentage = wrap.clientHeight * 100 / wrap.scrollHeight;
        widthPercentage = wrap.clientWidth * 100 / wrap.scrollWidth;
        var sizeHeight = heightPercentage < 100 ? heightPercentage + "%" : "";
        var sizeWidth = widthPercentage < 100 ? widthPercentage + "%" : "";
        this.setState({
          sizeHeight: sizeHeight,
          sizeWidth: sizeWidth
        });
      }
    }, {
      key: "render",
      value: function render() {
        var _this3 = this;
        /* eslint-disable */


        var _props = this.props,
            native = _props.native,
            viewStyle = _props.viewStyle,
            wrapStyle = _props.wrapStyle,
            viewClass = _props.viewClass,
            children = _props.children,
            viewComponent = _props.viewComponent,
            wrapClass = _props.wrapClass,
            noresize = _props.noresize,
            className = _props.className,
            others = (0, _objectWithoutProperties3.default)(_props, ["native", "viewStyle", "wrapStyle", "viewClass", "children", "viewComponent", "wrapClass", "noresize", "className"]);
        var _state = this.state,
            moveX = _state.moveX,
            moveY = _state.moveY,
            sizeWidth = _state.sizeWidth,
            sizeHeight = _state.sizeHeight;
        /* eslint-enable */

        var style = wrapStyle;
        var gutter = (0, _scrollbarWidth.getScrollBarWidth)();

        if (gutter) {
          var gutterWith = "-" + gutter + "px";

          if (Array.isArray(wrapStyle)) {
            style = Object.assign.apply(null, [].concat((0, _toConsumableArray3.default)(wrapStyle), [{
              marginRight: gutterWith,
              marginBottom: gutterWith
            }]));
          } else {
            style = Object.assign({}, wrapStyle, {
              marginRight: gutterWith,
              marginBottom: gutterWith
            });
          }
        }

        var view = _react2.default.createElement(viewComponent, {
          className: this.classNames("el-scrollbar__view", viewClass),
          style: viewStyle,
          ref: "resize"
        }, children);

        var nodes = void 0;

        if (!native) {
          var wrap = _react2.default.createElement("div", (0, _extends3.default)({}, others, {
            ref: "wrap",
            key: 0,
            style: style,
            onScroll: this.handleScroll.bind(this),
            className: this.classNames(wrapClass, "el-scrollbar__wrap", gutter ? "" : "el-scrollbar__wrap--hidden-default")
          }), view);

          nodes = [wrap, _react2.default.createElement(_Bar.Bar, {
            key: 1,
            move: moveX,
            size: sizeWidth,
            getParentWrap: function getParentWrap() {
              return _this3.wrap;
            }
          }), _react2.default.createElement(_Bar.Bar, {
            key: 2,
            move: moveY,
            size: sizeHeight,
            getParentWrap: function getParentWrap() {
              return _this3.wrap;
            },
            vertical: true
          })];
        } else {
          nodes = [_react2.default.createElement("div", (0, _extends3.default)({}, others, {
            key: 0,
            ref: "wrap",
            className: this.classNames(wrapClass, "el-scrollbar__wrap"),
            style: style
          }), view)];
        }

        return _react2.default.createElement("div", {
          className: this.classNames("el-scrollbar", className)
        }, nodes);
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }, {
      key: "wrap",
      get: function get() {
        return this.refs.wrap;
      }
    }]);
    return Scrollbar;
  }(_libs.Component);

  Scrollbar.propTypes = {
    native: _libs.PropTypes.bool,
    wrapStyle: _libs.PropTypes.object,
    wrapClass: _libs.PropTypes.oneOfType([_libs.PropTypes.string, _libs.PropTypes.object]),
    viewClass: _libs.PropTypes.oneOfType([_libs.PropTypes.string, _libs.PropTypes.object]),
    viewStyle: _libs.PropTypes.object,
    className: _libs.PropTypes.string,
    viewComponent: _libs.PropTypes.oneOfType([_libs.PropTypes.string, _libs.PropTypes.element]),
    noresize: _libs.PropTypes.bool
  };
  Scrollbar.defaultProps = {
    viewComponent: "div"
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Scrollbar, "Scrollbar", "src/scrollbar/Scrollbar.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}