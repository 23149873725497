import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _assets3Dew } from "./assets";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _assets = _assets3Dew();

  var _assets2 = _interopRequireDefault(_assets);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Toast = function (_Component) {
    (0, _inherits3.default)(Toast, _Component);

    function Toast(props) {
      (0, _classCallCheck3.default)(this, Toast);

      var _this = (0, _possibleConstructorReturn3.default)(this, (Toast.__proto__ || Object.getPrototypeOf(Toast)).call(this, props));

      _this.state = {
        visible: false
      };
      return _this;
    }

    (0, _createClass3.default)(Toast, [{
      key: "componentDidMount",
      value: function componentDidMount() {
        this.setState({
          visible: true
        });
        this.startTimer();
      }
    }, {
      key: "componentWillUnmount",
      value: function componentWillUnmount() {
        this.stopTimer();
      }
    }, {
      key: "onClose",
      value: function onClose() {
        this.stopTimer();
        this.setState({
          visible: false
        });
      }
    }, {
      key: "startTimer",
      value: function startTimer() {
        var _this2 = this;

        if (this.props.duration > 0) {
          this.timeout = setTimeout(function () {
            _this2.onClose();
          }, this.props.duration);
        }
      }
    }, {
      key: "stopTimer",
      value: function stopTimer() {
        clearTimeout(this.timeout);
      }
    }, {
      key: "render",
      value: function render() {
        var _this3 = this;

        var _props = this.props,
            iconClass = _props.iconClass,
            customClass = _props.customClass;
        return _react2.default.createElement(_libs.Transition, {
          name: "el-message-fade",
          onAfterLeave: function onAfterLeave() {
            _this3.props.willUnmount();
          }
        }, _react2.default.createElement(_libs.View, {
          show: this.state.visible
        }, _react2.default.createElement("div", {
          className: this.classNames("el-message", customClass),
          onMouseEnter: this.stopTimer.bind(this),
          onMouseLeave: this.startTimer.bind(this)
        }, !iconClass && _react2.default.createElement("img", {
          className: "el-message__img",
          src: _assets2.default[this.props.type]
        }), _react2.default.createElement("div", {
          className: this.classNames("el-message__group", {
            "is-with-icon": iconClass
          })
        }, iconClass && _react2.default.createElement("i", {
          className: this.classNames("el-message__icon", iconClass)
        }), _react2.default.createElement("p", null, this.props.message), this.props.showClose && _react2.default.createElement("div", {
          className: "el-message__closeBtn el-icon-close",
          onClick: this.onClose.bind(this)
        })))));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return Toast;
  }(_libs.Component);

  var _default = Toast;
  exports.default = _default;
  Toast.propTypes = {
    type: _libs.PropTypes.oneOf(["success", "warning", "info", "error"]),
    message: _libs.PropTypes.oneOfType([_libs.PropTypes.string, _libs.PropTypes.element]).isRequired,
    duration: _libs.PropTypes.number,
    showClose: _libs.PropTypes.bool,
    customClass: _libs.PropTypes.string,
    iconClass: _libs.PropTypes.string
  };
  Toast.defaultProps = {
    type: "info",
    duration: 3000,
    showClose: false
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Toast, "Toast", "src/message/Toast.jsx");
    reactHotLoader.register(_default, "default", "src/message/Toast.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}