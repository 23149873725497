import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Cover = function (_Component) {
    (0, _inherits3.default)(Cover, _Component);

    function Cover(props) {
      (0, _classCallCheck3.default)(this, Cover);

      var _this = (0, _possibleConstructorReturn3.default)(this, (Cover.__proto__ || Object.getPrototypeOf(Cover)).call(this, props));

      _this.state = {
        dragOver: false
      };
      return _this;
    }

    (0, _createClass3.default)(Cover, [{
      key: "handleDragover",
      value: function handleDragover(e) {
        e.preventDefault();

        if (!this.props.disabled) {
          this.setState({
            dragOver: true
          });
        }
      }
    }, {
      key: "handleDragleave",
      value: function handleDragleave(e) {
        e.preventDefault();
        this.setState({
          dragOver: false
        });
      }
    }, {
      key: "onDrop",
      value: function onDrop(e) {
        if (this.props.disabled) return;
        e.preventDefault();
        this.setState({
          dragOver: false
        });
        this.props.onFile(e.dataTransfer.files);
      }
    }, {
      key: "render",
      value: function render() {
        var _this2 = this;

        var dragOver = this.state.dragOver;
        return _react2.default.createElement("div", {
          className: this.classNames({
            "el-upload-dragger": true,
            "is-dragover": dragOver
          }),
          onDrop: function onDrop(e) {
            return _this2.onDrop(e);
          },
          onDragOver: function onDragOver(e) {
            return _this2.handleDragover(e);
          },
          onDragLeave: function onDragLeave(e) {
            return _this2.handleDragleave(e);
          }
        }, this.props.children);
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return Cover;
  }(_libs.Component);

  Cover.defaultProps = {
    onFile: Function
  };
  var _default = Cover;
  exports.default = _default;
  Cover.propTypes = {
    onFile: _libs.PropTypes.func,
    disabled: _libs.PropTypes.bool
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Cover, "Cover", "src/upload/Cover.jsx");
    reactHotLoader.register(_default, "default", "src/upload/Cover.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}