import _defineProperty from "babel-runtime/helpers/defineProperty";
import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _ajax3Dew } from "./ajax";
import { dew as _Cover3Dew } from "./Cover";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _defineProperty2 = _defineProperty;

  var _defineProperty3 = _interopRequireDefault(_defineProperty2);

  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _ajax = _ajax3Dew();

  var _ajax2 = _interopRequireDefault(_ajax);

  var _Cover = _Cover3Dew();

  var _Cover2 = _interopRequireDefault(_Cover);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var AjaxUpload = function (_Component) {
    (0, _inherits3.default)(AjaxUpload, _Component);

    function AjaxUpload(props) {
      (0, _classCallCheck3.default)(this, AjaxUpload);
      return (0, _possibleConstructorReturn3.default)(this, (AjaxUpload.__proto__ || Object.getPrototypeOf(AjaxUpload)).call(this, props));
    }

    (0, _createClass3.default)(AjaxUpload, [{
      key: "isImage",
      value: function isImage(str) {
        return str.indexOf("image") !== -1;
      }
    }, {
      key: "handleChange",
      value: function handleChange(e) {
        if (e.target instanceof HTMLInputElement) {
          var files = e.target.files;

          if (!files) {
            return;
          }

          this.uploadFiles(files);
          this.refs.input.value = null;
        }
      }
    }, {
      key: "uploadFiles",
      value: function uploadFiles(files) {
        var _this2 = this;

        var _props = this.props,
            multiple = _props.multiple,
            limit = _props.limit,
            onExceed = _props.onExceed,
            fileList = _props.fileList;

        if (limit && fileList.length + files.length > limit) {
          onExceed && onExceed(files, fileList);
          return;
        }

        var postFiles = Array.prototype.slice.call(files);

        if (postFiles.length === 0) {
          return;
        }

        if (!multiple) {
          postFiles = postFiles.slice(0, 1);
        }

        postFiles.forEach(function (file) {
          _this2.props.onStart(file);

          if (_this2.props.autoUpload) _this2.upload(file);
        });
      }
    }, {
      key: "upload",
      value: function upload(rawFile, file) {
        var _this3 = this;

        var beforeUpload = this.props.beforeUpload;

        if (!beforeUpload) {
          return this.post(rawFile);
        }

        var before = beforeUpload(rawFile);

        if (before && before.then) {
          before.then(function (processedFile) {
            if (Object.prototype.toString.call(processedFile) === "[object File]") {
              _this3.post(processedFile);
            } else {
              _this3.post(rawFile);
            }
          }, function () {
            if (file && typeof _this3.props.onRemove === "function") _this3.props.onRemove(file);
          });
        } else if (before !== false) {
          this.post(rawFile);
        } else {
          if (file && typeof this.props.onRemove === "function") this.props.onRemove(file);
        }
      }
    }, {
      key: "post",
      value: function post(file) {
        var _props2 = this.props,
            filename = _props2.name,
            headers = _props2.headers,
            withCredentials = _props2.withCredentials,
            data = _props2.data,
            action = _props2.action,
            _onProgress = _props2.onProgress,
            _onSuccess = _props2.onSuccess,
            _onError = _props2.onError;
        var _props$httpRequest = this.props.httpRequest,
            httpRequest = _props$httpRequest === undefined ? _ajax2.default : _props$httpRequest;
        var req = httpRequest({
          headers: headers,
          withCredentials: withCredentials,
          file: file,
          data: data,
          filename: filename,
          action: action,
          onProgress: function onProgress(e) {
            return _onProgress(e, file);
          },
          onSuccess: function onSuccess(res) {
            return _onSuccess(res, file);
          },
          onError: function onError(err) {
            return _onError(err, file);
          }
        });

        if (req && req.then) {
          req.then(_onSuccess, _onError);
        }
      }
    }, {
      key: "handleClick",
      value: function handleClick() {
        if (!this.props.disabled) {
          this.refs.input.click();
        }
      }
    }, {
      key: "render",
      value: function render() {
        var _this4 = this;

        var _props3 = this.props,
            drag = _props3.drag,
            multiple = _props3.multiple,
            accept = _props3.accept,
            listType = _props3.listType,
            disabled = _props3.disabled;
        return _react2.default.createElement("div", {
          className: this.classNames((0, _defineProperty3.default)({
            "el-upload": true
          }, "el-upload--" + listType, true)),
          onClick: function onClick() {
            return _this4.handleClick();
          }
        }, drag ? _react2.default.createElement(_Cover2.default, {
          disabled: disabled,
          onFile: function onFile(file) {
            return _this4.uploadFiles(file);
          }
        }, this.props.children) : this.props.children, _react2.default.createElement("input", {
          className: "el-upload__input",
          type: "file",
          ref: "input",
          onChange: function onChange(e) {
            return _this4.handleChange(e);
          },
          multiple: multiple,
          accept: accept
        }));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return AjaxUpload;
  }(_libs.Component);

  AjaxUpload.defaultProps = {
    name: "file"
  };
  var _default = AjaxUpload;
  exports.default = _default;
  AjaxUpload.propTypes = {
    drag: _libs.PropTypes.bool,
    data: _libs.PropTypes.object,
    action: _libs.PropTypes.string.isRequired,
    name: _libs.PropTypes.string,
    accept: _libs.PropTypes.string,
    headers: _libs.PropTypes.object,
    withCredentials: _libs.PropTypes.bool,
    multiple: _libs.PropTypes.bool,
    onStart: _libs.PropTypes.func,
    onProgress: _libs.PropTypes.func,
    onSuccess: _libs.PropTypes.func,
    onError: _libs.PropTypes.func,
    beforeUpload: _libs.PropTypes.func,
    autoUpload: _libs.PropTypes.bool,
    listType: _libs.PropTypes.string,
    fileList: _libs.PropTypes.array,
    disabled: _libs.PropTypes.bool,
    limit: _libs.PropTypes.number,
    onExceed: _libs.PropTypes.func,
    httpRequest: _libs.PropTypes.func
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(AjaxUpload, "AjaxUpload", "src/upload/AjaxUpload.jsx");
    reactHotLoader.register(_default, "default", "src/upload/AjaxUpload.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}