import { dew as _Radio3Dew } from "./Radio";
import { dew as _RadioButton3Dew } from "./RadioButton";
import { dew as _RadioGroup3Dew } from "./RadioGroup";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Radio = _Radio3Dew();

  var _Radio2 = _interopRequireDefault(_Radio);

  var _RadioButton = _RadioButton3Dew();

  var _RadioButton2 = _interopRequireDefault(_RadioButton);

  var _RadioGroup = _RadioGroup3Dew();

  var _RadioGroup2 = _interopRequireDefault(_RadioGroup);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  _Radio2.default.Button = _RadioButton2.default;
  _Radio2.default.Group = _RadioGroup2.default;
  var _default = _Radio2.default;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(_default, "default", "src/radio/index.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}