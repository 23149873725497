import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.scrollIntoView = scrollIntoView;

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var loadStyleString = exports.loadStyleString = function loadStyleString(css) {
    var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    if (document.getElementById(id)) return;
    var style = document.createElement("style");
    style.type = "text/css";
    style.id = id;

    try {
      style.appendChild(document.createTextNode(css));
    } catch (ex) {
      style.styleSheet.cssText = css;
    }

    var head = document.getElementsByTagName("head")[0];
    head.appendChild(style);
  };

  var isServer = false;
  /* istanbul ignore next */

  var on = exports.on = function () {
    if (!isServer && document.addEventListener) {
      return function (element, event, handler) {
        if (element && event && handler) {
          element.addEventListener(event, handler, false);
        }
      };
    } else {
      return function (element, event, handler) {
        if (element && event && handler) {
          element.attachEvent("on" + event, handler);
        }
      };
    }
  }();
  /* istanbul ignore next */


  var off = exports.off = function () {
    if (!isServer && document.removeEventListener) {
      return function (element, event, handler) {
        if (element && event) {
          element.removeEventListener(event, handler, false);
        }
      };
    } else {
      return function (element, event, handler) {
        if (element && event) {
          element.detachEvent("on" + event, handler);
        }
      };
    }
  }();

  function scrollIntoView(container, selected) {
    if (isServer) return;

    if (!selected) {
      container.scrollTop = 0;
      return;
    }

    var top = selected.offsetTop;
    var bottom = selected.offsetTop + selected.offsetHeight;
    var viewRectTop = container.scrollTop;
    var viewRectBottom = viewRectTop + container.clientHeight;

    if (top < viewRectTop) {
      container.scrollTop = top;
    } else if (bottom > viewRectBottom) {
      container.scrollTop = bottom - container.clientHeight;
    }
  }

  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(loadStyleString, "loadStyleString", "libs/utils/dom.js");
    reactHotLoader.register(isServer, "isServer", "libs/utils/dom.js");
    reactHotLoader.register(on, "on", "libs/utils/dom.js");
    reactHotLoader.register(off, "off", "libs/utils/dom.js");
    reactHotLoader.register(scrollIntoView, "scrollIntoView", "libs/utils/dom.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}