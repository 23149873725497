import _extends from "babel-runtime/helpers/extends";
import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _createClass from "babel-runtime/helpers/createClass";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import _throttleDebounce2 from "throttle-debounce";
import { dew as _libs2Dew } from "../../libs";
import { dew as _BasePicker4Dew } from "./BasePicker";
import { dew as _TimePanel3Dew } from "./panel/TimePanel";
import { dew as _constants2Dew } from "./constants";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _extends2 = _extends;

  var _extends3 = _interopRequireDefault(_extends2);

  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _throttleDebounce = _throttleDebounce2;

  var _libs = _libs2Dew();

  var _BasePicker2 = _BasePicker4Dew();

  var _BasePicker3 = _interopRequireDefault(_BasePicker2);

  var _TimePanel = _TimePanel3Dew();

  var _TimePanel2 = _interopRequireDefault(_TimePanel);

  var _constants = _constants2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  function converSelectRange(props) {
    var selectableRange = [];

    if (props.selectableRange) {
      var ranges = props.selectableRange;
      var parser = _constants.TYPE_VALUE_RESOLVER_MAP.datetimerange.parser;
      var format = _constants.DEFAULT_FORMATS.timerange;
      ranges = Array.isArray(ranges) ? ranges : [ranges];
      selectableRange = ranges.map(function (range) {
        return parser(range, format);
      });
    }

    return selectableRange;
  }

  var TimePicker = function (_BasePicker) {
    (0, _inherits3.default)(TimePicker, _BasePicker);
    (0, _createClass3.default)(TimePicker, null, [{
      key: "propTypes",
      // why this is used, goto: http://exploringjs.com/es6/ch_classes.html
      get: function get() {
        var result = Object.assign({}, {
          // '18:30:00 - 20:30:00'
          // or ['09:30:00 - 12:00:00', '14:30:00 - 18:30:00']
          selectableRange: _libs.PropTypes.oneOfType([_libs.PropTypes.string, _libs.PropTypes.arrayOf(_libs.PropTypes.string)])
        }, _BasePicker3.default.propTypes);
        return result;
      }
    }, {
      key: "defaultProps",
      get: function get() {
        var result = Object.assign({}, _BasePicker3.default.defaultProps);
        return result;
      }
    }]);

    function TimePicker(props) {
      (0, _classCallCheck3.default)(this, TimePicker);

      var _this = (0, _possibleConstructorReturn3.default)(this, (TimePicker.__proto__ || Object.getPrototypeOf(TimePicker)).call(this, props, "time", {}));

      _this._onSelectionChange = (0, _throttleDebounce.debounce)(200, _this.onSelectionChange.bind(_this));
      return _this;
    }

    (0, _createClass3.default)(TimePicker, [{
      key: "onSelectionChange",
      value: function onSelectionChange(start, end) {
        this.refs.inputRoot.refs.input.setSelectionRange(start, end);
        this.refs.inputRoot.refs.input.focus();
      }
    }, {
      key: "pickerPanel",
      value: function pickerPanel(state, props) {
        var _this2 = this;

        return _react2.default.createElement(_TimePanel2.default, (0, _extends3.default)({}, props, {
          currentDate: state.value,
          onCancel: function onCancel() {
            return _this2.setState({
              pickerVisible: false
            });
          },
          onPicked: this.onPicked.bind(this),
          onSelectRangeChange: this._onSelectionChange,
          selectableRange: converSelectRange(props)
        }));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return TimePicker;
  }(_BasePicker3.default);

  var _default = TimePicker;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(converSelectRange, "converSelectRange", "src/date-picker/TimePicker.jsx");
    reactHotLoader.register(TimePicker, "TimePicker", "src/date-picker/TimePicker.jsx");
    reactHotLoader.register(_default, "default", "src/date-picker/TimePicker.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}