import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import _reactDom3 from "react-dom";
import _popper3 from "popper.js";
import { dew as _libs2Dew } from "../../libs";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _reactDom = _reactDom3;

  var _reactDom2 = _interopRequireDefault(_reactDom);

  var _popper = _popper3;

  var _popper2 = _interopRequireDefault(_popper);

  var _libs = _libs2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var DropdownMenu = function (_Component) {
    (0, _inherits3.default)(DropdownMenu, _Component);

    function DropdownMenu(props) {
      (0, _classCallCheck3.default)(this, DropdownMenu);

      var _this = (0, _possibleConstructorReturn3.default)(this, (DropdownMenu.__proto__ || Object.getPrototypeOf(DropdownMenu)).call(this, props));

      _this.state = {
        showPopper: false
      };
      return _this;
    }

    (0, _createClass3.default)(DropdownMenu, [{
      key: "onVisibleChange",
      value: function onVisibleChange(visible) {
        this.setState({
          showPopper: visible
        });
      }
    }, {
      key: "onEnter",
      value: function onEnter() {
        var parent = _reactDom2.default.findDOMNode(this.parent());

        this.popperJS = new _popper2.default(parent, this.refs.popper, {
          placement: this.placement(),
          modifiers: {
            computeStyle: {
              gpuAcceleration: false
            }
          }
        });
      }
    }, {
      key: "onAfterLeave",
      value: function onAfterLeave() {
        this.popperJS.destroy();
      }
    }, {
      key: "parent",
      value: function parent() {
        return this.context.component;
      }
    }, {
      key: "placement",
      value: function placement() {
        return "bottom-" + this.parent().props.menuAlign;
      }
    }, {
      key: "render",
      value: function render() {
        return _react2.default.createElement(_libs.Transition, {
          name: "el-zoom-in-top",
          onEnter: this.onEnter.bind(this),
          onAfterLeave: this.onAfterLeave.bind(this)
        }, _react2.default.createElement(_libs.View, {
          show: this.state.showPopper
        }, _react2.default.createElement("ul", {
          ref: "popper",
          style: this.style(),
          className: this.className("el-dropdown-menu")
        }, this.props.children)));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return DropdownMenu;
  }(_libs.Component);

  var _default = DropdownMenu;
  exports.default = _default;
  DropdownMenu.contextTypes = {
    component: _libs.PropTypes.any
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(DropdownMenu, "DropdownMenu", "src/dropdown/DropdownMenu.jsx");
    reactHotLoader.register(_default, "default", "src/dropdown/DropdownMenu.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}