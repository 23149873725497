import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getScrollBarWidth = getScrollBarWidth;

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var scrollBarWidth = void 0;

  function getScrollBarWidth() {
    if (scrollBarWidth !== undefined) return scrollBarWidth;
    var outer = document.createElement("div");
    outer.className = "el-scrollbar__wrap";
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.position = "absolute";
    outer.style.top = "-9999px";
    document.body.appendChild(outer);
    var widthNoScroll = outer.offsetWidth;
    outer.style.overflow = "scroll";
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);
    var widthWithScroll = inner.offsetWidth;
    scrollBarWidth = widthNoScroll - widthWithScroll;
    outer.parentNode.removeChild(outer);
    return scrollBarWidth;
  }

  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(scrollBarWidth, "scrollBarWidth", "src/scrollbar/scrollbar-width.js");
    reactHotLoader.register(getScrollBarWidth, "getScrollBarWidth", "src/scrollbar/scrollbar-width.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}