import { dew as _DateTable2Dew } from "./DateTable";
import { dew as _MonthTable2Dew } from "./MonthTable";
import { dew as _YearTable2Dew } from "./YearTable";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _DateTable = _DateTable2Dew();

  Object.defineProperty(exports, "DateTable", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_DateTable).default;
    }
  });

  var _MonthTable = _MonthTable2Dew();

  Object.defineProperty(exports, "MonthTable", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_MonthTable).default;
    }
  });

  var _YearTable = _YearTable2Dew();

  Object.defineProperty(exports, "YearTable", {
    enumerable: true,
    get: function get() {
      return _interopRequireDefault(_YearTable).default;
    }
  });

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  return exports;
}