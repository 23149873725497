import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var TabPane = function (_Component) {
    (0, _inherits3.default)(TabPane, _Component);

    function TabPane() {
      (0, _classCallCheck3.default)(this, TabPane);
      return (0, _possibleConstructorReturn3.default)(this, (TabPane.__proto__ || Object.getPrototypeOf(TabPane)).apply(this, arguments));
    }

    (0, _createClass3.default)(TabPane, [{
      key: "render",
      value: function render() {
        return _react2.default.createElement("div", {
          style: this.style(),
          className: this.className("el-tab-pane")
        }, this.props.children);
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return TabPane;
  }(_libs.Component);

  var _default = TabPane;
  exports.default = _default;
  TabPane.propTypes = {
    label: _libs.PropTypes.oneOfType([_libs.PropTypes.string, _libs.PropTypes.node]),
    name: _libs.PropTypes.string,
    disabled: _libs.PropTypes.bool,
    closable: _libs.PropTypes.bool
  };
  TabPane.defaultProps = {
    disabled: false,
    closable: false
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(TabPane, "TabPane", "src/tabs/TabPane.jsx");
    reactHotLoader.register(_default, "default", "src/tabs/TabPane.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}