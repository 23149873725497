import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _MixinComponent4Dew } from "./MixinComponent";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _MixinComponent2 = _MixinComponent4Dew();

  var _MixinComponent3 = _interopRequireDefault(_MixinComponent2);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var MenuItem = function (_MixinComponent) {
    (0, _inherits3.default)(MenuItem, _MixinComponent);

    function MenuItem(props) {
      (0, _classCallCheck3.default)(this, MenuItem);

      var _this = (0, _possibleConstructorReturn3.default)(this, (MenuItem.__proto__ || Object.getPrototypeOf(MenuItem)).call(this, props));

      _this.instanceType = "MenuItem";
      return _this;
    }

    (0, _createClass3.default)(MenuItem, [{
      key: "componentDidMount",
      value: function componentDidMount() {
        this.rootMenu().state.menuItems[this.props.index] = this;
      }
    }, {
      key: "handleClick",
      value: function handleClick() {
        this.rootMenu().handleSelect(this.props.index, this.indexPath(), this);
      }
    }, {
      key: "active",
      value: function active() {
        return this.props.index === this.rootMenu().state.activeIndex;
      }
    }, {
      key: "render",
      value: function render() {
        return _react2.default.createElement("li", {
          style: this.style(),
          className: this.className("el-menu-item", {
            "is-active": this.active(),
            "is-disabled": this.props.disabled
          }),
          onClick: this.handleClick.bind(this)
        }, this.props.children);
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return MenuItem;
  }(_MixinComponent3.default);

  var _default = MenuItem;
  exports.default = _default;
  MenuItem.propTypes = {
    index: _libs.PropTypes.string.isRequired,
    disabled: _libs.PropTypes.bool
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(MenuItem, "MenuItem", "src/menu/MenuItem.jsx");
    reactHotLoader.register(_default, "default", "src/menu/MenuItem.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}