import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../../libs";
import { dew as _utils2Dew } from "../utils";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _utils = _utils2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var YearTable = function (_Component) {
    (0, _inherits3.default)(YearTable, _Component);

    function YearTable(props) {
      (0, _classCallCheck3.default)(this, YearTable);
      return (0, _possibleConstructorReturn3.default)(this, (YearTable.__proto__ || Object.getPrototypeOf(YearTable)).call(this, props));
    }

    (0, _createClass3.default)(YearTable, [{
      key: "getCellStyle",
      value: function getCellStyle(year) {
        var _props = this.props,
            disabledDate = _props.disabledDate,
            value = _props.value,
            date = _props.date;
        var style = {};
        var ndate = new Date(date);
        ndate.setFullYear(year);
        style.disabled = typeof disabledDate === "function" && disabledDate(ndate, _utils.SELECTION_MODES.YEAR);
        style.current = value && (0, _utils.deconstructDate)(value).year === year;
        return style;
      }
    }, {
      key: "handleYearTableClick",
      value: function handleYearTableClick(event) {
        var target = event.target;

        if (target.tagName === "A") {
          if ((0, _utils.hasClass)(target.parentNode, "disabled")) return;
          var year = target.textContent || target.innerText;
          this.props.onPick(+year);
        }
      }
    }, {
      key: "render",
      value: function render() {
        var date = this.props.date;
        var startYear = Math.floor((0, _utils.deconstructDate)(date).year / 10) * 10;
        return _react2.default.createElement("table", {
          onClick: this.handleYearTableClick.bind(this),
          className: "el-year-table"
        }, _react2.default.createElement("tbody", null, _react2.default.createElement("tr", null, _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 0))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear)), _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 1))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 1)), _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 2))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 2)), _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 3))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 3))), _react2.default.createElement("tr", null, _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 4))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 4)), _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 5))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 5)), _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 6))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 6)), _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 7))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 7))), _react2.default.createElement("tr", null, _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 8))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 8)), _react2.default.createElement("td", {
          className: this.classNames("available", this.getCellStyle(startYear + 9))
        }, _react2.default.createElement("a", {
          className: "cell"
        }, startYear + 9)), _react2.default.createElement("td", null), _react2.default.createElement("td", null))));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return YearTable;
  }(_libs.Component);

  var _default = YearTable;
  exports.default = _default;
  YearTable.propTypes = {
    value: _libs.PropTypes.instanceOf(Date),
    date: _libs.PropTypes.instanceOf(Date).isRequired,
    // (year: number)=>
    onPick: _libs.PropTypes.func.isRequired,
    // (Date)=>boolean
    disabledDate: _libs.PropTypes.func
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(YearTable, "YearTable", "src/date-picker/basic/YearTable.jsx");
    reactHotLoader.register(_default, "default", "src/date-picker/basic/YearTable.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}