import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../../libs";
import { dew as _utils2Dew } from "../utils";
import { dew as _locale3Dew } from "../../locale";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _utils = _utils2Dew();

  var _locale = _locale3Dew();

  var _locale2 = _interopRequireDefault(_locale);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var MonthTable = function (_Component) {
    (0, _inherits3.default)(MonthTable, _Component);

    function MonthTable(props) {
      (0, _classCallCheck3.default)(this, MonthTable);
      return (0, _possibleConstructorReturn3.default)(this, (MonthTable.__proto__ || Object.getPrototypeOf(MonthTable)).call(this, props));
    }

    (0, _createClass3.default)(MonthTable, [{
      key: "getCellStyle",
      value: function getCellStyle(month) {
        var _props = this.props,
            date = _props.date,
            disabledDate = _props.disabledDate,
            value = _props.value;
        var style = {};
        var ndate = new Date(date);
        ndate.setMonth(month); // in the element repo, you could see the original code that only disable current month only when all days contains in this month are disabled
        // which i don't think is a good design, so i changed disabledDate callback with an additional type param to solve this kind issue.
        // so the caller can handle different picker views on each switch arm condition.

        style.disabled = typeof disabledDate === "function" && disabledDate(ndate, _utils.SELECTION_MODES.MONTH);
        style.current = value && (0, _utils.deconstructDate)(value).month === month;
        return style;
      }
    }, {
      key: "handleMonthTableClick",
      value: function handleMonthTableClick(event) {
        var target = event.target;
        if (target.tagName !== "A") return;
        if ((0, _utils.hasClass)(target.parentNode, "disabled")) return;
        var column = target.parentNode.cellIndex;
        var row = target.parentNode.parentNode.rowIndex;
        var month = row * 4 + column;
        this.props.onPick(month);
      }
    }, {
      key: "render",
      value: function render() {
        var _this2 = this;

        var $t = _locale2.default.t;
        var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
        return _react2.default.createElement("table", {
          onClick: this.handleMonthTableClick.bind(this),
          className: "el-month-table"
        }, _react2.default.createElement("tbody", null, months.map(function (key, idx) {
          return _react2.default.createElement("td", {
            className: _this2.classNames(_this2.getCellStyle(idx)),
            key: idx
          }, _react2.default.createElement("a", {
            className: "cell"
          }, $t("el.datepicker.months." + key)));
        }).reduce(function (col, item) {
          var tararr = void 0;

          if (!(Array.isArray(col[0]) && col[0].length !== 4)) {
            col.unshift([]);
          }

          tararr = col[0];
          tararr.push(item);
          return col;
        }, []).reverse().map(function (e, idx) {
          return _react2.default.createElement("tr", {
            key: idx
          }, e);
        })));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return MonthTable;
  }(_libs.Component);

  var _default = MonthTable;
  exports.default = _default;
  MonthTable.propTypes = {
    // current date, specific to view
    date: _libs.PropTypes.instanceOf(Date).isRequired,
    // user picked value, value: Date|null
    value: _libs.PropTypes.instanceOf(Date),
    onPick: _libs.PropTypes.func.isRequired,
    // (Date)=>boolean
    disabledDate: _libs.PropTypes.func
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(MonthTable, "MonthTable", "src/date-picker/basic/MonthTable.jsx");
    reactHotLoader.register(_default, "default", "src/date-picker/basic/MonthTable.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}