import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _createClass from "babel-runtime/helpers/createClass";
import _inherits from "babel-runtime/helpers/inherits";
import { dew as _libs2Dew } from "../../../libs";
import { dew as _utils2Dew } from "../../../libs/utils";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PopperBase = undefined;
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _libs = _libs2Dew();

  var _utils = _utils2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var PopperBase = exports.PopperBase = function (_Component) {
    (0, _inherits3.default)(PopperBase, _Component);
    (0, _createClass3.default)(PopperBase, null, [{
      key: "propTypes",
      get: function get() {
        return {
          //()=>HtmlElement
          getPopperRefElement: _libs.PropTypes.func,
          popperMixinOption: _libs.PropTypes.object
        };
      }
    }]);

    function PopperBase(props) {
      (0, _classCallCheck3.default)(this, PopperBase);

      var _this = (0, _possibleConstructorReturn3.default)(this, (PopperBase.__proto__ || Object.getPrototypeOf(PopperBase)).call(this, props));

      _utils.PopperReactMixin.call(_this, function () {
        return _this.refs.root;
      }, props.getPopperRefElement, Object.assign({
        boundariesPadding: 0,
        gpuAcceleration: false
      }, props.popperMixinOption));

      return _this;
    }

    (0, _createClass3.default)(PopperBase, [{
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return PopperBase;
  }(_libs.Component);

  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(PopperBase, "PopperBase", "src/date-picker/panel/PopperBase.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}