import { dew as _format3Dew } from "./format";
import { dew as _zhCN3Dew } from "./lang/zh-CN";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _format = _format3Dew();

  var _format2 = _interopRequireDefault(_format);

  var _zhCN = _zhCN3Dew();

  var _zhCN2 = _interopRequireDefault(_zhCN);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var _lang = _zhCN2.default;

  function use(lang) {
    _lang = lang;
  }

  function t(path, options) {
    var array = path.split(".");
    var current = _lang;

    for (var i = 0, j = array.length; i < j; i++) {
      var property = array[i];
      var value = current[property];
      if (i === j - 1) return (0, _format2.default)(value, options);
      if (!value) return "";
      current = value;
    }

    return "";
  }

  var _default = {
    use: use,
    t: t
  };
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(_lang, "_lang", "src/locale/index.js");
    reactHotLoader.register(use, "use", "src/locale/index.js");
    reactHotLoader.register(t, "t", "src/locale/index.js");
    reactHotLoader.register(_default, "default", "src/locale/index.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}