import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import _popper3 from "popper.js";
import { dew as _libs2Dew } from "../../libs";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _popper = _popper3;

  var _popper2 = _interopRequireDefault(_popper);

  var _libs = _libs2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Tooltip = function (_Component) {
    (0, _inherits3.default)(Tooltip, _Component);

    function Tooltip(props) {
      (0, _classCallCheck3.default)(this, Tooltip);

      var _this = (0, _possibleConstructorReturn3.default)(this, (Tooltip.__proto__ || Object.getPrototypeOf(Tooltip)).call(this, props));

      _this.state = {
        showPopper: false
      };
      return _this;
    }

    (0, _createClass3.default)(Tooltip, [{
      key: "componentWillReceiveProps",
      value: function componentWillReceiveProps(props) {
        if (props.visible !== this.props.visible) {
          this.setState({
            showPopper: props.visible
          });
        }
      }
    }, {
      key: "showPopper",
      value: function showPopper() {
        var _this2 = this;

        if (!this.props.manual) {
          this.timeout = setTimeout(function () {
            _this2.setState({
              showPopper: true
            });
          }, this.props.openDelay);
        }
      }
    }, {
      key: "hidePopper",
      value: function hidePopper() {
        if (!this.props.manual) {
          clearTimeout(this.timeout);
          this.setState({
            showPopper: false
          });
        }
      }
    }, {
      key: "onEnter",
      value: function onEnter() {
        var _refs = this.refs,
            popper = _refs.popper,
            reference = _refs.reference,
            arrow = _refs.arrow;

        if (arrow) {
          arrow.setAttribute("x-arrow", "");
        }

        this.popperJS = new _popper2.default(reference, popper, {
          placement: this.props.placement,
          modifiers: {
            computeStyle: {
              gpuAcceleration: false
            }
          }
        });
      }
    }, {
      key: "onAfterLeave",
      value: function onAfterLeave() {
        this.popperJS.destroy();
      }
    }, {
      key: "render",
      value: function render() {
        var _props = this.props,
            effect = _props.effect,
            content = _props.content,
            disabled = _props.disabled,
            transition = _props.transition,
            visibleArrow = _props.visibleArrow;
        return _react2.default.createElement("div", {
          style: this.style(),
          className: this.className("el-tooltip"),
          onMouseEnter: this.showPopper.bind(this),
          onMouseLeave: this.hidePopper.bind(this)
        }, _react2.default.createElement("div", {
          ref: "reference",
          className: "el-tooltip__rel"
        }, _react2.default.createElement("div", null, this.props.children)), !disabled && _react2.default.createElement(_libs.Transition, {
          name: transition,
          onEnter: this.onEnter.bind(this),
          onAfterLeave: this.onAfterLeave.bind(this)
        }, _react2.default.createElement(_libs.View, {
          show: this.state.showPopper
        }, _react2.default.createElement("div", {
          ref: "popper",
          className: this.classNames("el-tooltip__popper", "is-" + effect)
        }, _react2.default.createElement("div", null, content), visibleArrow && _react2.default.createElement("div", {
          ref: "arrow",
          className: "popper__arrow"
        })))));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return Tooltip;
  }(_libs.Component);

  Tooltip.defaultProps = {
    effect: "dark",
    placement: "bottom",
    disabled: false,
    transition: "fade-in-linear",
    visibleArrow: true,
    openDelay: 0,
    manual: false
  };
  var _default = Tooltip;
  exports.default = _default;
  Tooltip.propTypes = {
    // 默认提供的主题: dark, light
    effect: _libs.PropTypes.string,
    // 显示的内容，也可以通过 slot#content 传入 DOM
    content: _libs.PropTypes.oneOfType([_libs.PropTypes.node, _libs.PropTypes.string]),
    // Tooltip 的出现位置 [top, top-start, top-end, bottom, bottom-start, bottom-end, left, left-start, left-end, right, right-start, right-end]
    placement: _libs.PropTypes.oneOf(["top", "top-start", "top-end", "bottom", "bottom-start", "bottom-end", "left", "left-start", "left-end", "right", "right-start", "right-end"]),
    // 状态是否可用
    disabled: _libs.PropTypes.bool,
    // 渐变动画定义
    transition: _libs.PropTypes.string,
    // 是否显示 Tooltip 箭头
    visibleArrow: _libs.PropTypes.bool,
    // 延迟出现(单位: 毫秒)
    openDelay: _libs.PropTypes.number,
    // 手动控制模式，设置为 true 后，mouseenter 和 mouseleave 事件将不会生效
    manual: _libs.PropTypes.bool,
    // 手动控制状态的展示
    visible: _libs.PropTypes.bool
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Tooltip, "Tooltip", "src/tooltip/Tooltip.jsx");
    reactHotLoader.register(_default, "default", "src/tooltip/Tooltip.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}