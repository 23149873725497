import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = calcTextareaHeight;

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var hiddenTextarea = void 0;
  var HIDDEN_STYLE = "\n  height:0 !important;\n  visibility:hidden !important;\n  overflow:hidden !important;\n  position:absolute !important;\n  z-index:-1000 !important;\n  top:0 !important;\n  right:0 !important\n";
  var CONTEXT_STYLE = ["letter-spacing", "line-height", "padding-top", "padding-bottom", "font-family", "font-weight", "font-size", "text-rendering", "text-transform", "width", "text-indent", "padding-left", "padding-right", "border-width", "box-sizing"];

  function calculateNodeStyling(node) {
    var style = window.getComputedStyle(node);
    var boxSizing = style.getPropertyValue("box-sizing");
    var paddingSize = parseFloat(style.getPropertyValue("padding-bottom")) + parseFloat(style.getPropertyValue("padding-top"));
    var borderSize = parseFloat(style.getPropertyValue("border-bottom-width")) + parseFloat(style.getPropertyValue("border-top-width"));
    var contextStyle = CONTEXT_STYLE.map(function (name) {
      return name + ":" + style.getPropertyValue(name);
    }).join(";");
    return {
      contextStyle: contextStyle,
      paddingSize: paddingSize,
      borderSize: borderSize,
      boxSizing: boxSizing
    };
  }

  function calcTextareaHeight(targetNode) {
    var minRows = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var maxRows = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    if (!hiddenTextarea) {
      hiddenTextarea = document.createElement("textarea");
      document.body && document.body.appendChild(hiddenTextarea);
    }

    var _calculateNodeStyling = calculateNodeStyling(targetNode),
        paddingSize = _calculateNodeStyling.paddingSize,
        borderSize = _calculateNodeStyling.borderSize,
        boxSizing = _calculateNodeStyling.boxSizing,
        contextStyle = _calculateNodeStyling.contextStyle;

    hiddenTextarea.setAttribute("style", contextStyle + ";" + HIDDEN_STYLE);
    hiddenTextarea.value = targetNode.value || targetNode.placeholder || "";
    var height = hiddenTextarea.scrollHeight;

    if (boxSizing === "border-box") {
      height = height + borderSize;
    } else if (boxSizing === "content-box") {
      height = height - paddingSize;
    }

    hiddenTextarea.value = "";
    var singleRowHeight = hiddenTextarea.scrollHeight - paddingSize;

    if (minRows !== null) {
      var minHeight = singleRowHeight * minRows;

      if (boxSizing === "border-box") {
        minHeight = minHeight + paddingSize + borderSize;
      }

      height = Math.max(minHeight, height);
    }

    if (maxRows !== null) {
      var maxHeight = singleRowHeight * maxRows;

      if (boxSizing === "border-box") {
        maxHeight = maxHeight + paddingSize + borderSize;
      }

      height = Math.min(maxHeight, height);
    }

    return {
      height: height + "px"
    };
  }

  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(hiddenTextarea, "hiddenTextarea", "src/input/calcTextareaHeight.js");
    reactHotLoader.register(HIDDEN_STYLE, "HIDDEN_STYLE", "src/input/calcTextareaHeight.js");
    reactHotLoader.register(CONTEXT_STYLE, "CONTEXT_STYLE", "src/input/calcTextareaHeight.js");
    reactHotLoader.register(calculateNodeStyling, "calculateNodeStyling", "src/input/calcTextareaHeight.js");
    reactHotLoader.register(calcTextareaHeight, "calcTextareaHeight", "src/input/calcTextareaHeight.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}