import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import { dew as _libs2Dew } from "../../libs";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _libs = _libs2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var MixinComponent = function (_Component) {
    (0, _inherits3.default)(MixinComponent, _Component);

    function MixinComponent() {
      (0, _classCallCheck3.default)(this, MixinComponent);
      return (0, _possibleConstructorReturn3.default)(this, (MixinComponent.__proto__ || Object.getPrototypeOf(MixinComponent)).apply(this, arguments));
    }

    (0, _createClass3.default)(MixinComponent, [{
      key: "parent",
      value: function parent() {
        return this.context.component;
      }
    }, {
      key: "indexPath",
      value: function indexPath() {
        var path = [this.props.index];
        var parent = this.parent();

        while (parent.instanceType !== "Menu") {
          if (parent.props.index) {
            path.unshift(parent.props.index);
          }

          parent = parent.parent();
        }

        return path;
      }
    }, {
      key: "rootMenu",
      value: function rootMenu() {
        var parent = this.parent();

        while (parent.instanceType !== "Menu") {
          parent = parent.parent();
        }

        return parent;
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return MixinComponent;
  }(_libs.Component);

  var _default = MixinComponent;
  exports.default = _default;
  MixinComponent.contextTypes = {
    component: _libs.PropTypes.any
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(MixinComponent, "MixinComponent", "src/menu/MixinComponent.jsx");
    reactHotLoader.register(_default, "default", "src/menu/MixinComponent.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}