import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var CollapseItem = function (_Component) {
    (0, _inherits3.default)(CollapseItem, _Component);

    function CollapseItem(props) {
      (0, _classCallCheck3.default)(this, CollapseItem);
      return (0, _possibleConstructorReturn3.default)(this, (CollapseItem.__proto__ || Object.getPrototypeOf(CollapseItem)).call(this, props));
    }

    (0, _createClass3.default)(CollapseItem, [{
      key: "render",
      value: function render() {
        var _props = this.props,
            title = _props.title,
            isActive = _props.isActive,
            _onClick = _props.onClick,
            name = _props.name;
        return _react2.default.createElement("div", {
          className: this.classNames({
            "el-collapse-item": true,
            "is-active": isActive
          })
        }, _react2.default.createElement("div", {
          className: "el-collapse-item__header",
          onClick: function onClick() {
            return _onClick(name);
          }
        }, _react2.default.createElement("i", {
          className: "el-collapse-item__header__arrow el-icon-arrow-right"
        }), title), _react2.default.createElement(_libs.CollapseTransition, {
          isShow: isActive
        }, _react2.default.createElement("div", {
          className: "el-collapse-item__wrap"
        }, _react2.default.createElement("div", {
          className: "el-collapse-item__content"
        }, this.props.children))));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return CollapseItem;
  }(_libs.Component);

  var _default = CollapseItem;
  exports.default = _default;
  CollapseItem.propTypes = {
    onClick: _libs.PropTypes.func,
    isActive: _libs.PropTypes.bool,
    title: _libs.PropTypes.node,
    name: _libs.PropTypes.string
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(CollapseItem, "CollapseItem", "src/collapse/CollapseItem.jsx");
    reactHotLoader.register(_default, "default", "src/collapse/CollapseItem.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}