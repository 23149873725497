import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var NODE_KEY = exports.NODE_KEY = "$treeNodeId";

  var markNodeData = exports.markNodeData = function markNodeData(node, data) {
    if (data[NODE_KEY]) return;
    Object.defineProperty(data, NODE_KEY, {
      value: node.id,
      enumerable: false,
      configurable: false,
      writable: false
    });
  };

  var getNodeKey = exports.getNodeKey = function getNodeKey(key, data) {
    if (!key) return data[NODE_KEY];
    return data[key];
  };

  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(NODE_KEY, "NODE_KEY", "src/tree/model/util.js");
    reactHotLoader.register(markNodeData, "markNodeData", "src/tree/model/util.js");
    reactHotLoader.register(getNodeKey, "getNodeKey", "src/tree/model/util.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}