import { dew as _CheckBox3Dew } from "./CheckBox";
import { dew as _CheckBoxGroup3Dew } from "./CheckBoxGroup";
import { dew as _CheckBoxButton3Dew } from "./CheckBoxButton";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _CheckBox = _CheckBox3Dew();

  var _CheckBox2 = _interopRequireDefault(_CheckBox);

  var _CheckBoxGroup = _CheckBoxGroup3Dew();

  var _CheckBoxGroup2 = _interopRequireDefault(_CheckBoxGroup);

  var _CheckBoxButton = _CheckBoxButton3Dew();

  var _CheckBoxButton2 = _interopRequireDefault(_CheckBoxButton);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  _CheckBox2.default.Group = _CheckBoxGroup2.default;
  _CheckBox2.default.Button = _CheckBoxButton2.default;
  var _default = _CheckBox2.default;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(_default, "default", "src/checkbox/index.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}