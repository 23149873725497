import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react2 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _utils2Dew } from "./utils";
import { dew as _checkbox3Dew } from "../checkbox";
import { dew as _tag3Dew } from "../tag";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react2;

  var React = _interopRequireWildcard(_react);

  var _libs = _libs2Dew();

  var _utils = _utils2Dew();

  var _checkbox = _checkbox3Dew();

  var _checkbox2 = _interopRequireDefault(_checkbox);

  var _tag = _tag3Dew();

  var _tag2 = _interopRequireDefault(_tag);

  function _interopRequireWildcard(obj) {
    if (obj && obj.__esModule) {
      return obj;
    } else {
      var newObj = {};

      if (obj != null) {
        for (var key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key];
        }
      }

      newObj.default = obj;
      return newObj;
    }
  }

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var TableFooter = function (_Component) {
    (0, _inherits3.default)(TableFooter, _Component);

    function TableFooter() {
      (0, _classCallCheck3.default)(this, TableFooter);
      return (0, _possibleConstructorReturn3.default)(this, (TableFooter.__proto__ || Object.getPrototypeOf(TableFooter)).apply(this, arguments));
    }

    (0, _createClass3.default)(TableFooter, [{
      key: "isCellHidden",
      value: function isCellHidden(index, columns) {
        var fixed = this.props.fixed;

        if (fixed === true || fixed === "left") {
          return index >= this.leftFixedCount;
        } else if (fixed === "right") {
          var before = 0;

          for (var i = 0; i < index; i++) {
            before += columns[i].colSpan;
          }

          return before < this.columnsCount - this.rightFixedCount;
        } else {
          return index < this.leftFixedCount || index >= this.columnsCount - this.rightFixedCount;
        }
      }
    }, {
      key: "render",
      value: function render() {
        var _this2 = this;

        var _props = this.props,
            tableStoreState = _props.tableStoreState,
            layout = _props.layout,
            fixed = _props.fixed,
            summaryMethod = _props.summaryMethod,
            sumText = _props.sumText;
        var sums = summaryMethod ? summaryMethod(tableStoreState.columns, tableStoreState.data) : tableStoreState.columns.map(function (column, index) {
          if (index === 0) {
            return sumText;
          }

          var result = tableStoreState.data.reduce(function (pre, data) {
            return pre + parseFloat((0, _utils.getValueByPath)(data, column.property));
          }, 0);
          return isNaN(result) ? "" : result;
        });
        return React.createElement("table", {
          className: "el-table__footer",
          cellSpacing: "0",
          cellPadding: "0",
          style: this.style({
            borderSpacing: 0,
            border: 0
          })
        }, React.createElement("colgroup", null, tableStoreState.columns.map(function (column, index) {
          return React.createElement("col", {
            width: column.realWidth,
            style: {
              width: column.realWidth
            },
            key: index
          });
        }), !fixed && React.createElement("col", {
          width: layout.scrollY ? layout.gutterWidth : 0,
          style: {
            width: layout.scrollY ? layout.gutterWidth : 0
          }
        })), React.createElement("tbody", null, React.createElement("tr", null, tableStoreState.columns.map(function (column, index) {
          return React.createElement("td", {
            key: index,
            colSpan: column.colSpan,
            rowSpan: column.rowSpan,
            className: _this2.className(column.headerAlign, column.className, column.labelClassName, column.columnKey, {
              "is-hidden": _this2.isCellHidden(index, tableStoreState.columns),
              "is-leaf": !column.subColumns
            })
          }, React.createElement("div", {
            className: "cell"
          }, sums[index]));
        }), !fixed && React.createElement("td", {
          className: "gutter",
          style: {
            width: layout.scrollY ? layout.gutterWidth : 0
          }
        }))));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }, {
      key: "columnsCount",
      get: function get() {
        return this.props.tableStoreState.columns.length;
      }
    }, {
      key: "leftFixedCount",
      get: function get() {
        return this.props.tableStoreState.fixedColumns.length;
      }
    }, {
      key: "rightFixedCount",
      get: function get() {
        return this.props.tableStoreState.rightFixedColumns.length;
      }
    }]);
    return TableFooter;
  }(_libs.Component);

  var _default = TableFooter;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(TableFooter, "TableFooter", "src/table/TableFooter.jsx");
    reactHotLoader.register(_default, "default", "src/table/TableFooter.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}