import _defineProperty from "babel-runtime/helpers/defineProperty";
import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _src2Dew } from "../../src";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _defineProperty2 = _defineProperty;

  var _defineProperty3 = _interopRequireDefault(_defineProperty2);

  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _src = _src2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var UploadList = function (_Component) {
    (0, _inherits3.default)(UploadList, _Component);

    function UploadList(props) {
      (0, _classCallCheck3.default)(this, UploadList);
      return (0, _possibleConstructorReturn3.default)(this, (UploadList.__proto__ || Object.getPrototypeOf(UploadList)).call(this, props));
    }

    (0, _createClass3.default)(UploadList, [{
      key: "uploadList",
      value: function uploadList() {
        var _this2 = this;

        var _context = this.context,
            onPreview = _context.onPreview,
            onRemove = _context.onRemove;
        var _props = this.props,
            listType = _props.listType,
            fileList = _props.fileList;

        var isFinished = function isFinished(status) {
          return status === "success";
        };

        if (listType === "none") return null;
        return _react2.default.createElement("ul", {
          className: this.classNames((0, _defineProperty3.default)({
            "el-upload-list": true
          }, "el-upload-list--" + listType, true))
        }, fileList.map(function (file) {
          return _react2.default.createElement("li", {
            className: _this2.classNames((0, _defineProperty3.default)({
              "el-upload-list__item": true
            }, "is-" + file.status, true)),
            key: file.uid
          }, ["picture-card", "picture"].includes(listType) && isFinished(file.status) && _react2.default.createElement("img", {
            className: "el-upload-list__item-thumbnail",
            src: file.url,
            alt: ""
          }), _react2.default.createElement("a", {
            className: "el-upload-list__item-name",
            onClick: function onClick() {
              return onPreview(file);
            }
          }, _react2.default.createElement("i", {
            className: "el-icon-document"
          }), file.name), _react2.default.createElement("label", {
            className: "el-upload-list__item-status-label"
          }, _react2.default.createElement("i", {
            className: _this2.classNames({
              "el-icon-upload-success": true,
              "el-icon-circle-check": listType === "text",
              "el-icon-check": ["picture-card", "picture"].includes(listType)
            })
          })), _react2.default.createElement("i", {
            className: "el-icon-close",
            onClick: function onClick() {
              return onRemove(file);
            }
          }), _react2.default.createElement(_libs.View, {
            className: "el-upload-list__item-actions",
            show: listType === "picture-card" && isFinished(file.status)
          }, _react2.default.createElement("span", null, _react2.default.createElement("span", {
            onClick: function onClick() {
              return onPreview(file);
            },
            className: "el-upload-list__item-preview"
          }, _react2.default.createElement("i", {
            className: "el-icon-view"
          })), _react2.default.createElement("span", {
            className: "el-upload-list__item-delete",
            onClick: function onClick() {
              return onRemove(file);
            }
          }, _react2.default.createElement("i", {
            className: "el-icon-delete2"
          })))), file.status === "uploading" && _react2.default.createElement(_src.Progress, {
            strokeWidth: listType === "picture-card" ? 6 : 2,
            type: listType === "picture-card" ? "circle" : "line",
            percentage: parseInt(file.percentage, 10),
            status: isFinished(file.status) && file.showProgress ? "success" : ""
          }));
        }));
      }
    }, {
      key: "render",
      value: function render() {
        return _react2.default.createElement(_libs.Transition, {
          name: "list"
        }, this.uploadList());
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return UploadList;
  }(_libs.Component);

  var _default = UploadList;
  exports.default = _default;
  UploadList.contextTypes = {
    onPreview: _libs.PropTypes.func,
    onRemove: _libs.PropTypes.func
  };
  UploadList.propTypes = {
    listType: _libs.PropTypes.string,
    fileList: _libs.PropTypes.array
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(UploadList, "UploadList", "src/upload/UploadList.jsx");
    reactHotLoader.register(_default, "default", "src/upload/UploadList.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}