import { dew as _Scrollbar2Dew } from "./Scrollbar";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Scrollbar = _Scrollbar2Dew();

  Object.defineProperty(exports, "Scrollbar", {
    enumerable: true,
    get: function get() {
      return _Scrollbar.Scrollbar;
    }
  });
  return exports;
}