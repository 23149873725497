import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _input3Dew } from "../input";
import { dew as _checkbox3Dew } from "../checkbox";
import { dew as _locale3Dew } from "../locale";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _input = _input3Dew();

  var _input2 = _interopRequireDefault(_input);

  var _checkbox = _checkbox3Dew();

  var _checkbox2 = _interopRequireDefault(_checkbox);

  var _locale = _locale3Dew();

  var _locale2 = _interopRequireDefault(_locale);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var TransferPanel = function (_Component) {
    (0, _inherits3.default)(TransferPanel, _Component);

    function TransferPanel(props) {
      (0, _classCallCheck3.default)(this, TransferPanel);

      var _this = (0, _possibleConstructorReturn3.default)(this, (TransferPanel.__proto__ || Object.getPrototypeOf(TransferPanel)).call(this, props));

      _this.handleMouseEnter = function () {
        return _this.setState({
          inputHover: true
        });
      };

      _this.handleMouseLeave = function () {
        return _this.setState({
          inputHover: false
        });
      };

      _this.clearQuery = function () {
        if (_this.inputIcon === "circle-close") {
          _this.setState({
            query: ""
          });
        }
      };

      _this.handleAllCheckedChange = function (ischecked) {
        var checked = ischecked ? _this.checkableData.map(function (item) {
          return item[_this.keyProp];
        }) : [];

        _this.props.onChange(checked);
      };

      _this.handleCheckedChange = function (value) {
        _this.props.onChange(value);
      };

      _this.handleInputChange = function (value) {
        _this.setState({
          query: value
        });
      };

      _this.state = {
        query: "",
        inputHover: false
      };
      return _this;
    }

    (0, _createClass3.default)(TransferPanel, [{
      key: "render",
      value: function render() {
        var _this2 = this;

        var _props = this.props,
            filterable = _props.filterable,
            title = _props.title,
            data = _props.data,
            renderContent = _props.renderContent,
            checked = _props.checked,
            placeholder = _props.placeholder;
        var query = this.state.query;
        return _react2.default.createElement("div", {
          className: "el-transfer-panel"
        }, _react2.default.createElement("p", {
          className: "el-transfer-panel__header"
        }, title), _react2.default.createElement("div", {
          className: "el-transfer-panel__body"
        }, filterable && _react2.default.createElement(_input2.default, {
          className: "el-transfer-panel__filter",
          value: query,
          size: "small",
          placeholder: placeholder,
          icon: this.inputIcon,
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave,
          onIconClick: this.clearQuery,
          onChange: this.handleInputChange
        }), _react2.default.createElement(_libs.View, {
          show: !this.hasNoMatch && data.length > 0
        }, _react2.default.createElement(_checkbox2.default.Group, {
          value: checked,
          "v-show": "",
          className: this.classNames({
            "is-filterable": filterable,
            "el-transfer-panel__list": true
          }),
          onChange: this.handleCheckedChange
        }, this.filteredData.map(function (item, index) {
          return _react2.default.createElement(_checkbox2.default, {
            className: "el-transfer-panel__item",
            label: item[_this2.labelProp],
            disabled: item[_this2.disabledProp],
            value: item[_this2.keyProp],
            key: index
          }, _react2.default.createElement(OptionContent, {
            option: item,
            renderContent: renderContent,
            labelProp: _this2.labelProp,
            keyProp: _this2.keyProp
          }));
        }))), _react2.default.createElement(_libs.View, {
          show: this.hasNoMatch
        }, _react2.default.createElement("p", {
          className: "el-transfer-panel__empty"
        }, _locale2.default.t("el.transfer.noMatch"))), _react2.default.createElement(_libs.View, {
          show: data.length === 0 && !this.hasNoMatch
        }, _react2.default.createElement("p", {
          className: "el-transfer-panel__empty"
        }, _locale2.default.t("el.transfer.noData")))), _react2.default.createElement("p", {
          className: "el-transfer-panel__footer"
        }, _react2.default.createElement(_checkbox2.default, {
          checked: this.allChecked,
          onChange: this.handleAllCheckedChange,
          indeterminate: this.isIndeterminate
        }, this.checkedSummary), this.props.children));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }, {
      key: "allChecked",
      get: function get() {
        var _this3 = this;

        var checkableDataKeys = this.checkableData.map(function (item) {
          return item[_this3.keyProp];
        });
        return checkableDataKeys.length > 0 && checkableDataKeys.every(function (item) {
          return _this3.props.checked.includes(item);
        });
      }
    }, {
      key: "filteredData",
      get: function get() {
        var _this4 = this;

        return this.props.data.filter(function (item) {
          if (typeof _this4.props.filterMethod === "function") {
            return _this4.props.filterMethod(_this4.state.query, item);
          } else {
            var label = item[_this4.labelProp] || item[_this4.keyProp].toString();

            return label.toLowerCase().includes(_this4.state.query.toLowerCase());
          }
        });
      }
    }, {
      key: "checkableData",
      get: function get() {
        var _this5 = this;

        return this.filteredData.filter(function (item) {
          return !item[_this5.disabledProp];
        });
      }
    }, {
      key: "checkedSummary",
      get: function get() {
        var checkedLength = this.props.checked.length;
        var dataLength = this.props.data.length;
        var _props$footerFormat = this.props.footerFormat,
            noChecked = _props$footerFormat.noChecked,
            hasChecked = _props$footerFormat.hasChecked;

        if (noChecked && hasChecked) {
          return checkedLength > 0 ? hasChecked.replace(/\${checked}/g, checkedLength).replace(/\${total}/g, dataLength) : noChecked.replace(/\${total}/g, dataLength);
        } else {
          return checkedLength > 0 ? _locale2.default.t("el.transfer.hasCheckedFormat", {
            total: dataLength,
            checked: checkedLength
          }) : _locale2.default.t("el.transfer.noCheckedFormat", {
            total: dataLength
          });
        }
      }
    }, {
      key: "isIndeterminate",
      get: function get() {
        var checkedLength = this.props.checked.length;
        return checkedLength > 0 && checkedLength < this.checkableData.length;
      }
    }, {
      key: "hasNoMatch",
      get: function get() {
        var query = this.state.query;
        return query.length > 0 && this.filteredData.length === 0;
      }
    }, {
      key: "inputIcon",
      get: function get() {
        var _state = this.state,
            query = _state.query,
            inputHover = _state.inputHover;
        return query.length > 0 && inputHover ? "circle-close" : "search";
      }
    }, {
      key: "labelProp",
      get: function get() {
        return this.props.propsAlias.label;
      }
    }, {
      key: "keyProp",
      get: function get() {
        return this.props.propsAlias.key;
      }
    }, {
      key: "disabledProp",
      get: function get() {
        return this.props.propsAlias.disabled;
      }
    }]);
    return TransferPanel;
  }(_libs.Component);

  TransferPanel.propTypes = {
    data: _libs.PropTypes.array,
    renderContent: _libs.PropTypes.func,
    placeholder: _libs.PropTypes.string,
    title: _libs.PropTypes.string,
    filterable: _libs.PropTypes.bool,
    footerFormat: _libs.PropTypes.object,
    filterMethod: _libs.PropTypes.func,
    propsAlias: _libs.PropTypes.object,
    onChange: _libs.PropTypes.func,
    checked: _libs.PropTypes.array
  };
  TransferPanel.defaultProps = {
    data: [],
    footerFormat: {},
    propsAlias: {},
    onChange: function onChange() {}
  };
  var _default = TransferPanel;
  exports.default = _default;

  var OptionContent = function OptionContent(_ref) {
    var option = _ref.option,
        renderContent = _ref.renderContent,
        labelProp = _ref.labelProp,
        keyProp = _ref.keyProp;
    return renderContent ? renderContent(option) : _react2.default.createElement("span", null, option[labelProp] || option[keyProp]);
  };

  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(TransferPanel, "TransferPanel", "src/transfer/TransferPanel.jsx");
    reactHotLoader.register(OptionContent, "OptionContent", "src/transfer/TransferPanel.jsx");
    reactHotLoader.register(_default, "default", "src/transfer/TransferPanel.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}