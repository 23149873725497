import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import _reactDom3 from "react-dom";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MountBody = undefined;
  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _reactDom = _reactDom3;

  var _reactDom2 = _interopRequireDefault(_reactDom);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var MountBody = exports.MountBody = function (_Component) {
    (0, _inherits3.default)(MountBody, _Component);

    function MountBody() {
      (0, _classCallCheck3.default)(this, MountBody);
      return (0, _possibleConstructorReturn3.default)(this, (MountBody.__proto__ || Object.getPrototypeOf(MountBody)).apply(this, arguments));
    }

    (0, _createClass3.default)(MountBody, [{
      key: "componentWillMount",
      value: function componentWillMount() {
        var c = _react2.default.cloneElement(this.props.children);

        this.tnode = document.createElement("div");
        document.body.appendChild(this.tnode);

        _reactDom2.default.render(c, this.tnode);
      }
    }, {
      key: "componentWillUnmount",
      value: function componentWillUnmount() {
        _reactDom2.default.unmountComponentAtNode(this.tnode);

        document.body.removeChild(this.tnode);
      }
    }, {
      key: "contains",
      value: function contains(evt) {
        var parent = this.tnode.childNodes[0];
        var rect = parent.getBoundingClientRect();
        var isContain = evt.clientX >= rect.left && evt.clientX <= rect.right && evt.clientY >= rect.top && evt.clientY <= rect.bottom;
        return isContain;
      }
    }, {
      key: "render",
      value: function render() {
        return null;
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return MountBody;
  }(_react.Component);

  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(MountBody, "MountBody", "src/date-picker/MountBody.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}