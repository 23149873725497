import { dew as _Select3Dew } from "./Select";
import { dew as _Option3Dew } from "./Option";
import { dew as _OptionGroup3Dew } from "./OptionGroup";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Select = _Select3Dew();

  var _Select2 = _interopRequireDefault(_Select);

  var _Option = _Option3Dew();

  var _Option2 = _interopRequireDefault(_Option);

  var _OptionGroup = _OptionGroup3Dew();

  var _OptionGroup2 = _interopRequireDefault(_OptionGroup);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  _Select2.default.Option = _Option2.default;
  _Select2.default.OptionGroup = _OptionGroup2.default;
  var _default = _Select2.default;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(_default, "default", "src/select/index.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}