import { dew as _Breadcrumb3Dew } from "./Breadcrumb";
import { dew as _BreadcrumbItem3Dew } from "./BreadcrumbItem";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Breadcrumb = _Breadcrumb3Dew();

  var _Breadcrumb2 = _interopRequireDefault(_Breadcrumb);

  var _BreadcrumbItem = _BreadcrumbItem3Dew();

  var _BreadcrumbItem2 = _interopRequireDefault(_BreadcrumbItem);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  _Breadcrumb2.default.Item = _BreadcrumbItem2.default;
  var _default = _Breadcrumb2.default;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(_default, "default", "src/breadcrumb/index.js");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}