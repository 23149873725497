import _extends from "babel-runtime/helpers/extends";
import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _createClass from "babel-runtime/helpers/createClass";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _utils2Dew } from "../../libs/utils";
import { dew as _libs2Dew } from "../../libs";
import { dew as _BasePicker4Dew } from "./BasePicker";
import { dew as _DateRangePanel3Dew } from "./panel/DateRangePanel";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _extends2 = _extends;

  var _extends3 = _interopRequireDefault(_extends2);

  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _utils = _utils2Dew();

  var _libs = _libs2Dew();

  var _BasePicker2 = _BasePicker4Dew();

  var _BasePicker3 = _interopRequireDefault(_BasePicker2);

  var _DateRangePanel = _DateRangePanel3Dew();

  var _DateRangePanel2 = _interopRequireDefault(_DateRangePanel);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var DateRangePicker = function (_BasePicker) {
    (0, _inherits3.default)(DateRangePicker, _BasePicker);
    (0, _createClass3.default)(DateRangePicker, null, [{
      key: "propTypes",
      get: function get() {
        return Object.assign({}, {
          rangeSeparator: _libs.PropTypes.string
        }, _BasePicker3.default.propTypes, // default value is been defined in ./constants file
        (0, _utils.pick)(_DateRangePanel2.default.propTypes, ["value", "isShowTime", "shortcuts", "firstDayOfWeek"]));
      }
    }, {
      key: "defaultProps",
      get: function get() {
        var result = Object.assign({}, _BasePicker3.default.defaultProps);
        return result;
      }
    }]);

    function DateRangePicker(props) {
      (0, _classCallCheck3.default)(this, DateRangePicker);
      return (0, _possibleConstructorReturn3.default)(this, (DateRangePicker.__proto__ || Object.getPrototypeOf(DateRangePicker)).call(this, props, "daterange", {}));
    }

    (0, _createClass3.default)(DateRangePicker, [{
      key: "getFormatSeparator",
      value: function getFormatSeparator() {
        return this.props.rangeSeparator;
      }
    }, {
      key: "pickerPanel",
      value: function pickerPanel(state, props) {
        var value = state.value;

        if (value instanceof Date) {
          value = [value, null];
        }

        return _react2.default.createElement(_DateRangePanel2.default, (0, _extends3.default)({}, props, {
          value: value,
          onPick: this.onPicked.bind(this)
        }));
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return DateRangePicker;
  }(_BasePicker3.default);

  var _default = DateRangePicker;
  exports.default = _default;
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(DateRangePicker, "DateRangePicker", "src/date-picker/DateRangePicker.jsx");
    reactHotLoader.register(_default, "default", "src/date-picker/DateRangePicker.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}