import _extends from "babel-runtime/helpers/extends";
import _objectWithoutProperties from "babel-runtime/helpers/objectWithoutProperties";
import _classCallCheck from "babel-runtime/helpers/classCallCheck";
import _createClass from "babel-runtime/helpers/createClass";
import _possibleConstructorReturn from "babel-runtime/helpers/possibleConstructorReturn";
import _inherits from "babel-runtime/helpers/inherits";
import _react3 from "react";
import { dew as _libs2Dew } from "../../libs";
import { dew as _calcTextareaHeight3Dew } from "./calcTextareaHeight";
import _reactHotLoader from "react-hot-loader";
var exports = {},
    _dewExec = false;
var module = {
  exports: exports
};
export function dew() {
  if (_dewExec) return module.exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _extends2 = _extends;

  var _extends3 = _interopRequireDefault(_extends2);

  var _objectWithoutProperties2 = _objectWithoutProperties;

  var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

  var _classCallCheck2 = _classCallCheck;

  var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

  var _createClass2 = _createClass;

  var _createClass3 = _interopRequireDefault(_createClass2);

  var _possibleConstructorReturn2 = _possibleConstructorReturn;

  var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

  var _inherits2 = _inherits;

  var _inherits3 = _interopRequireDefault(_inherits2);

  var _react = _react3;

  var _react2 = _interopRequireDefault(_react);

  var _libs = _libs2Dew();

  var _calcTextareaHeight = _calcTextareaHeight3Dew();

  var _calcTextareaHeight2 = _interopRequireDefault(_calcTextareaHeight);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  (function () {
    var enterModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).enterModule;
    enterModule && enterModule(module);
  })();

  var Input = function (_Component) {
    (0, _inherits3.default)(Input, _Component);

    function Input(props) {
      (0, _classCallCheck3.default)(this, Input);

      var _this = (0, _possibleConstructorReturn3.default)(this, (Input.__proto__ || Object.getPrototypeOf(Input)).call(this, props));

      _this.state = {
        textareaStyle: {
          resize: props.resize
        }
      };
      return _this;
    }

    (0, _createClass3.default)(Input, [{
      key: "componentDidMount",
      value: function componentDidMount() {
        this.resizeTextarea();
      }
      /* <Instance Methods */

    }, {
      key: "focus",
      value: function focus() {
        var _this2 = this;

        setTimeout(function () {
          (_this2.refs.input || _this2.refs.textarea).focus();
        });
      }
    }, {
      key: "blur",
      value: function blur() {
        var _this3 = this;

        setTimeout(function () {
          (_this3.refs.input || _this3.refs.textarea).blur();
        });
      }
      /* Instance Methods> */

    }, {
      key: "fixControlledValue",
      value: function fixControlledValue(value) {
        if (typeof value === "undefined" || value === null) {
          return "";
        }

        return value;
      }
    }, {
      key: "handleChange",
      value: function handleChange(e) {
        var onChange = this.props.onChange;

        if (onChange) {
          onChange(e.target.value);
        }

        this.resizeTextarea();
      }
    }, {
      key: "handleFocus",
      value: function handleFocus(e) {
        var onFocus = this.props.onFocus;
        if (onFocus) onFocus(e);
      }
    }, {
      key: "handleBlur",
      value: function handleBlur(e) {
        var onBlur = this.props.onBlur;
        if (this.props.trim) this.handleTrim();
        if (onBlur) onBlur(e);
      }
    }, {
      key: "handleTrim",
      value: function handleTrim() {
        this.refs.input.value = this.refs.input.value.trim();

        if (this.props.onChange) {
          // this's for controlled components
          this.props.onChange(this.refs.input.value.trim());
        }
      }
    }, {
      key: "handleIconClick",
      value: function handleIconClick(e) {
        if (this.props.onIconClick) {
          this.props.onIconClick(e);
        }
      }
    }, {
      key: "resizeTextarea",
      value: function resizeTextarea() {
        var _props = this.props,
            autosize = _props.autosize,
            type = _props.type;

        if (!autosize || type !== "textarea") {
          return;
        }

        var minRows = autosize.minRows;
        var maxRows = autosize.maxRows;
        var textareaCalcStyle = (0, _calcTextareaHeight2.default)(this.refs.textarea, minRows, maxRows);
        this.setState({
          textareaStyle: Object.assign({}, this.state.textareaStyle, textareaCalcStyle)
        });
      }
    }, {
      key: "render",
      value: function render() {
        var _props2 = this.props,
            type = _props2.type,
            size = _props2.size,
            prepend = _props2.prepend,
            append = _props2.append,
            icon = _props2.icon,
            autoComplete = _props2.autoComplete,
            validating = _props2.validating,
            rows = _props2.rows,
            onMouseEnter = _props2.onMouseEnter,
            onMouseLeave = _props2.onMouseLeave,
            trim = _props2.trim,
            otherProps = (0, _objectWithoutProperties3.default)(_props2, ["type", "size", "prepend", "append", "icon", "autoComplete", "validating", "rows", "onMouseEnter", "onMouseLeave", "trim"]);
        var classname = this.classNames(type === "textarea" ? "el-textarea" : "el-input", size && "el-input--" + size, {
          "is-disabled": this.props.disabled,
          "el-input-group": prepend || append,
          "el-input-group--append": !!append,
          "el-input-group--prepend": !!prepend
        });

        if ("value" in this.props) {
          otherProps.value = this.fixControlledValue(this.props.value);
          delete otherProps.defaultValue;
        }

        delete otherProps.resize;
        delete otherProps.style;
        delete otherProps.autosize;
        delete otherProps.onIconClick;

        if (type === "textarea") {
          return _react2.default.createElement("div", {
            style: this.style(),
            className: this.className(classname)
          }, _react2.default.createElement("textarea", (0, _extends3.default)({}, otherProps, {
            ref: "textarea",
            className: "el-textarea__inner",
            style: this.state.textareaStyle,
            rows: rows,
            onChange: this.handleChange.bind(this),
            onFocus: this.handleFocus.bind(this),
            onBlur: this.handleBlur.bind(this)
          })));
        } else {
          return _react2.default.createElement("div", {
            style: this.style(),
            className: this.className(classname),
            onMouseEnter: onMouseEnter,
            onMouseLeave: onMouseLeave
          }, prepend && _react2.default.createElement("div", {
            className: "el-input-group__prepend"
          }, prepend), typeof icon === "string" ? _react2.default.createElement("i", {
            className: "el-input__icon el-icon-" + icon,
            onClick: this.handleIconClick.bind(this)
          }, prepend) : icon, _react2.default.createElement("input", (0, _extends3.default)({}, otherProps, {
            ref: "input",
            type: type,
            className: "el-input__inner",
            autoComplete: autoComplete,
            onChange: this.handleChange.bind(this),
            onFocus: this.handleFocus.bind(this),
            onBlur: this.handleBlur.bind(this)
          })), validating && _react2.default.createElement("i", {
            className: "el-input__icon el-icon-loading"
          }), append && _react2.default.createElement("div", {
            className: "el-input-group__append"
          }, append));
        }
      }
    }, {
      key: "__reactstandin__regenerateByEval",
      // @ts-ignore
      value: function __reactstandin__regenerateByEval(key, code) {
        // @ts-ignore
        this[key] = eval(code);
      }
    }]);
    return Input;
  }(_libs.Component);

  Input.defaultProps = {
    type: "text",
    autosize: false,
    rows: 2,
    trim: false,
    autoComplete: "off"
  };
  var _default = Input;
  exports.default = _default;
  Input.propTypes = {
    // base
    type: _libs.PropTypes.string,
    icon: _libs.PropTypes.oneOfType([_libs.PropTypes.element, _libs.PropTypes.string]),
    disabled: _libs.PropTypes.bool,
    name: _libs.PropTypes.string,
    placeholder: _libs.PropTypes.string,
    readOnly: _libs.PropTypes.bool,
    autoFocus: _libs.PropTypes.bool,
    maxLength: _libs.PropTypes.number,
    minLength: _libs.PropTypes.number,
    defaultValue: _libs.PropTypes.any,
    value: _libs.PropTypes.any,
    trim: _libs.PropTypes.bool,
    // type !== 'textarea'
    size: _libs.PropTypes.oneOf(["large", "small", "mini"]),
    prepend: _libs.PropTypes.node,
    append: _libs.PropTypes.node,
    // type === 'textarea'
    autosize: _libs.PropTypes.oneOfType([_libs.PropTypes.bool, _libs.PropTypes.object]),
    rows: _libs.PropTypes.number,
    resize: _libs.PropTypes.oneOf(["none", "both", "horizontal", "vertical"]),
    // event
    onFocus: _libs.PropTypes.func,
    onBlur: _libs.PropTypes.func,
    onChange: _libs.PropTypes.func,
    onIconClick: _libs.PropTypes.func,
    onMouseEnter: _libs.PropTypes.func,
    onMouseLeave: _libs.PropTypes.func,
    // autoComplete
    autoComplete: _libs.PropTypes.string,
    inputSelect: _libs.PropTypes.func,
    // form related
    form: _libs.PropTypes.string,
    validating: _libs.PropTypes.bool
  };
  ;

  (function () {
    var reactHotLoader = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).default;

    if (!reactHotLoader) {
      return;
    }

    reactHotLoader.register(Input, "Input", "src/input/Input.jsx");
    reactHotLoader.register(_default, "default", "src/input/Input.jsx");
  })();

  ;

  (function () {
    var leaveModule = (typeof reactHotLoaderGlobal !== "undefined" ? reactHotLoaderGlobal : _reactHotLoader).leaveModule;
    leaveModule && leaveModule(module);
  })();

  return module.exports;
}